#tagListOverlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  display: none;
  z-index: 10000;
} // #tagListOverlay

#modal-tagList {
  display: none;
  position: fixed;
  min-width: 320px;
  width: 80%;
  max-height: 80%;
  background-color: #FFF;
  z-index: 10001;
  overflow-y: scroll;

  .header {
    background-color: #FFF;
    .closeBtnBox {
      margin-top: 18px;
      margin-left: 13px;
      img{height: 22px;}
    }

    .headBox {
      .head{
        margin-top: 15px;
        margin-left: 37px;
        font-size: 24px;
        color: #8E8E93;
      }
    } // .headBox
  } //.header

  #modalTagList{
    display: flex;
    flex-wrap: wrap;
    margin: 18px 15px 20px 0px;

    .tagCell{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 10px;
      margin: 0px 0px 9px 15px;
      width: auto;
      height: auto;
      background-color: #fff1f0;
      color:#FF0000;
      font-weight: 600;
      font-size: 14px;
      line-height: 30px;

      img{
        margin-right: 5px;
      }
    } //.tagCell
  } //.modalTagList

} // #modal-tagList

#tag_bar {
  display: none;
  position: fixed;
  bottom: 0;
  height: 55px;
  width: 100%;
  background-color: #FFFFFF;
  z-index: 1000;

  .tagBox{
    display: flex;
    overflow-x: scroll;
    align-items: center;
    height: 100%;

    .tagReload{
      margin-left: 10px;
      margin-right: -5px;
      img{
        height: 28px;
      }
    }

    .loadedTags{
      display: flex;

      .tagCell{
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        padding: 0px 10px;
        margin: 0px 0px 0px 15px;
        width: auto;
        height: 30px;
        background-color: #fff1f0;
        color:#FF0000;
        font-weight: 600;
        font-size: 14px;

        img{
          margin-right: 5px;
        }
      } //.tagCell
    } //.loadedTags

    .openNearTag-modal{
      padding:4.5px 10px 0px 10px;
      img{
        height: 10px;
      }
    }
  } //.tagBox

} //#tag_bar
