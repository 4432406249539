
#imageOverlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 1);
  display: none;
  z-index: 10000;
}

#modal-image {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  // max-width: 540px;
  // min-width: 320px;
  // max-height: 960px;
  // min-height: 576px;
  // max-height: 550px;
  position: fixed;
  background-color: #FFF;
  z-index: 10001;
  //padding: 5px;
  overflow: hidden;
  //padding: 20px 20px 40px 20px;

  .imgWrapper{
    display: flex;
    justify-content: center;
  }
  .imginner{
    position: relative;
    .zoomdown {
      position: absolute;
      top: 5px;
      right: 5px;
      img{
        margin: 0px;
        height: 30px;
        width: 30px;
      }
    }
  }

}

#modal-appimage {
  width: auto;
  height: 100vh;

}
