
#calenderOverlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 10000;
}

#modal-calender, #modal-calender-pulldown {
  display: none;
  position: relative;
  width: 80%;
  max-width: 500px;
  min-width: 320px;
  // height: 85%;
  // max-height: 550px;
  position: fixed;
  background-color: #FFF;
  z-index: 10001;
  padding: 5px;
  overflow: hidden;
  padding: 20px 20px 40px 20px;

  .btnBox {
    img {
      height: 22px;
      width: 22px;
    }
    &.right {
      margin-top: 30px;
      display: flex;
    }

    .btnOK {
      margin-left: auto;
      display: block;
      padding: 5px 10px;
      border: 1px solid $pink;
      background: $pink;
      color: #FFFFFF;
      font-size: 1.8rem;
    }
  } //.btnBox

  .pulldownArea {
    margin-top: 30px;

    .label {
      margin: 0;
      padding-left: 0;
      color: $gray-head;
      font-weight: 600;
      font-size: 1.8rem;
    }

    .pulldownEnd {
      margin-top: 30px;
    }

    .pulldownBox {
      margin-top: 10px;
      font-size: 1.8rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .str {
        padding-left: 7px;
        padding-right: 7px;
      }

      select {
        font-size: 1.8rem;
        background-color: white;
        border: thin solid $pink;
        border-radius: 4px;
        display: inline-block;
        font: inherit;
        line-height: 1.5em;
        padding: 0.5em 1em 0.5em 1em;

        margin: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
      }

      // select {
      //   background-image:
      //     linear-gradient(45deg, transparent 50%, #FFFFFF 50%),
      //     linear-gradient(135deg, #FFFFFF 50%, transparent 50%),
      //     linear-gradient(to right, $pink, $pink);
      //   background-position:
      //     calc(100% - 20px) calc(1em + 2px),
      //     calc(100% - 15px) calc(1em + 2px),
      //     100% 0;
      //   background-size:
      //     5px 5px,
      //     5px 5px,
      //     2.5em 2.5em;
      //   background-repeat: no-repeat;
      //   &:focus {
      //     background-image:
      //       linear-gradient(45deg, white 50%, transparent 50%),
      //       linear-gradient(135deg, transparent 50%, white 50%),
      //       linear-gradient(to right, $gray, $gray);
      //     background-position:
      //       calc(100% - 15px) 1em,
      //       calc(100% - 20px) 1em,
      //       100% 0;
      //     background-size:
      //       5px 5px,
      //       5px 5px,
      //       2.5em 2.5em;
      //     background-repeat: no-repeat;
      //     border-color: $gray;
      //     outline: 0;
      //   }
      // }

    }

  } //.pulldownArea

  .headBox {
    //margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .centerMonth {
      display: flex;
      justify-content: center;
      align-items: flex-end;

      .monthNum {
        font-size: 2.4rem;
        padding-right: 10px;
      }
      .monthEng {
        font-size: 1.3rem;
      }
    } //.centerMonth

  } //.headBox

  ul.calenderList {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      width: 14.2%;
      text-align: center;
      font-size: 1.8rem;
      padding-top: 5px;
      padding-bottom: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.head {
        font-weight: 600;
      }

      a.today {
        display: block;
        color: $pink;
      }
      a.before, a.after {
        display: block;
        color: #000000;
        &.invalid {
          color: $gray-head;
          pointer-events: none;
        }
      }
      a.other {
        display: block;
        color: $gray-head;
        pointer-events: none;
      }
      a.selected {
        width: 27px;
        height: 27px;
        display: block;
        background-color: $pink;
        color: #FFFFFF;
        border-radius: 50%;
      }

    }

  } //.calenderList

  .selectAllBtn {
    display:none;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #FF233E;
    width: 63px;
    height: 26px;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: #FF233E;
      &.selected {
        background-color: #FF233E;
        color: #ffffff;
      }
  } //.selectAllBtn

  #calenderDummy {
    position: relative;
  }

  #calenderNow {
    position: absolute;
    //margin-bottom: 10px;
    top: 55px;
    left: 0;
    width: 100%;
    padding: 0 20px;
    opacity: 1.0;

  }

  #calenderPrev {
    position: absolute;
    top: 55px;
    left: -100%;
    width: 100%;
    padding: 0 20px;
    opacity: 0;
  }

  #calenderNext {
    position: absolute;
    top: 55px;
    left: 100%;
    width: 100%;
    padding: 0 20px;
    opacity: 0;
  }

}
