// .page_top {
//   display: flex;
//   flex-direction: column;
//   justify-content: left;
// }
// .cf_top {
//   //height: 100%;
//   flex: 9;
// }

.heatmap-canvas {
  filter: blur(10px);
}

img.leaflet-tile-loaded {
  filter: grayscale(50%);
}

.leaflet-top.leaflet-left {
  width: 100%;
  display: flex;
  justify-content: center;
}

.top{
  height: 100%;

  .whitewrapper {
    display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    padding: 100%;
    background-color:#FFFFFF;
    z-index: 100;
  }

  #Sec01{
    //flex: 1;
    position: relative;
    margin-top: 38px;

    .inner{
      transition: 1.0s;
    }

  } //#Sec01

  #Sec02 {
    //flex: 1;
    //height: 100%;
    position: relative;

    .inner {
      margin: 0;
      width: auto;

      .filter {
        margin:0 13px 0px 13px;
        font-weight: bold;
        font-size: 32px;
        line-height: 48px;
        color: #231F20;
      }

      .card-area {
        display: flex;
        flex-wrap: wrap;
        justify-content:space-around;
        margin-bottom: 20px;
        }

      .notFound {
        display: none;
        padding-top: 150px;
        width: 100%;
        text-align: center;
        color: #000000;
        font-size: 2.6rem;
      }

    } //.inner

  } //#Sec02

  #Sec03 {
    display: none;
    //flex: 1;

    .inner {

    } //.inner

  } //#Sec03

} //.top

@media screen and (min-width: 1280px){

  .top{

    .whitewrapper {

    }

    #Sec01{

      .inner{

      }

    } //#Sec01

    #Sec02 {
      margin-left: 60px;
      margin-right: 60px;

      .inner {

        .filter {
          margin:21px 13px 0px 33px;
          font-weight: bold;
          font-size: 32px;
          line-height: 48px;
          color: #231F20;

        }

        .card-area {

          }

        .notFound {

        }

      } //.inner

    } //#Sec02

    #Sec03 {

      .inner {

      } //.inner

    } //#Sec03

  } //.top
}
