
#topads{
  .whitewrapper {
    display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    padding: 100%;
    background-color:#FFFFFF;
    z-index: 100;
  }
}

.detail {
  height: 100%;
  // overflow-y: hidden;
  // overflow-x: hidden;

  .whitewrapper {
    display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    padding: 100%;
    background-color:#FFFFFF;
    z-index: 100;
  }

  #Sec01{
    //flex: 1;
    position: relative;
    margin-top: 45px;

    .inner{
      transition: 1.0s;
    }

  } //#Sec01

  #Sec02 {
    //flex: 1;
    //height: 100%;
    position: relative;

    .inner {
      margin: 0;
      width: 100%;

    } //.inner

  } //#Sec02

  #Sec03 {
    display: none;
    //flex: 1;

    .inner {

    } //.inner

  } //#Sec03

} //.top



@media screen and (max-width: 768px) {
/* 768pxまでの幅の場合に適応される */

.detail {

  #Sec01{


  } //#Sec01

  #Sec03 {

    .inner {


    } //.inner

  } //#Sec03

} //.top

/* 768pxまでの幅の場合に適応される */
}
