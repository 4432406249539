
.card{

  #Sec01 {

    .inner {
            .card{
                background: #FFFFFF;
                max-width:375px;
                min-width:320px;
                box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
                font-family: HiraginoSans-W5;
                font-size: 1000px;
                font-color: #FF233E;
                letter-spacing: -1.16px;
                font-family: SFProText-Semibold;
                font-size: 42px;
                color: #231F20;
                letter-spacing: -3.97px;
                border-radius:8px;
        }
    } //.inner

  } //#Sec01

} //.menu1

// @media screen and (max-width: 768px) {
// /* 768pxまでの幅の場合に適応される */
//
// .menu1 {
//
//   #Sec01 {
//
//     .inner {
//
//       p.heading {
//         font-size: 1.3rem;
//       }
//
//       .text {
//         font-size: 1.0rem;
//       }
//
//     } //.inner
//
//   } //#Sec01
//
// } //.menu1
//
// /* 768pxまでの幅の場合に適応される */
// }
