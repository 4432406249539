
.policy{

  #Sec01{
    margin-top: 47px;

    .inner{
      position: relative;

      h1.title {
        padding-top: 30px;
        font-size: 1.8rem;
      }

      .content {
        padding-top: 20px;
        padding-bottom: 20px;
      }

    } //.inner

  } //#Sec01


} //.login
