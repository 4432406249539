
.about {
  overflow: hidden;

  #Sec01 {
    position: relative;

    .inner {
      // transition: 1.0s;

      .catchBox {
        // height: 100%;
        margin-bottom: -5px;
        // display: flex;
        // align-items: flex-end;

        video.video-pc {
          width: auto;
          height: auto;
          min-width: 100%;
          min-height: 100%;
          background-color: #000000;
          background-size: cover;
        }
        video.video-sp {
          display: none;
        }

        .filterOnVideo {
          position: absolute;
          top: 0;
          left: 0;
          width: auto;
          height: auto;
          min-width: 100%;
          min-height: calc(100% - 5px);
          background-color: #00000040;
        }

        .str4 {
          position: absolute;
          bottom: 54px;
          padding: 0 30px;
          color: #FFFFFF;
          font-size: 4.51vh;
          font-weight: 600;
          //.line-height: 2.2rem;
        }

        .arrowBox-sp {
          display: none;
        }

      } //.catchBox

    } //.inner

  } //#Sec01

/*ムービー追加*/
  #moviebox {
    text-align: center;
    padding: 40px 0;
    width: 100%;
    .movie {
      max-width: 800px;
      width: 90%;
      margin: 0 auto;
      height: 400px;
    }
  }

  #Sec02 {
    position: relative;

    .inner {

      .boxes {

        .row {
          display: flex;
          // &.row3 {
          //   display: none;
          // }

          .leftBox {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
            min-height: 300px;
            &.row1 {
              background-color: $pink-light;

              img.appIcon {
                display: block;
                width: 120px;
                height: 120px;
              }
            }
            &.row2 {
              background-color: $pink;

              .dlBox {
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 100%;

                .dummy {
                  width: 10px;
                }
                .str2 {
                  color: #FFFFFF;
                  font-size: 5.2rem;
                  font-weight: 600;
                }
                .arrow {
                  padding-right: 5px;
                  display: block;
                  width: auto;
                  height: 35px;
                }
              }
            }
            &.row3 {
              background-color: $pink-light;
              cursor: pointer;

              // .webStr {
              //   padding: 30px 45px;
              //   background-color: #FFFFFF;
              //   font-size: 34px;
              //   font-weight: 500;
              // }
              .webBox {
                img {
                  width: 256px;
                  height: auto;
                }
              }

            }

          } //.leftBox

          .rightBox {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
            min-height: 300px;
            &.row1 {
              background-color: $pink;

              .str1 {
                padding: 0 65px;
                color: #FFFFFF;
                font-size: 3.2rem;
                font-weight: 600;
                line-height: 5.0rem;
              }
            }
            &.row2 {
              background-color: $pink-light;

              .bannerBox {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                width: 100%;
                .banner-ios {
                  height: 67px;
                }
                .banner-android {
                  height: 67px;
                }
                .dummy {
                  width: 30px;
                }
              }
            }
            &.row3 {
              background-color: $pink;
              cursor: pointer;

              .strBox {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;

                .arrow {
                  padding-left: 5px;
                  display: block;
                  width: auto;
                  height: 35px;
                }
                .arrow-sp {
                  display: none;
                }
                .dummy {
                  width: 8%;
                }
                .str3 {
                  padding-right: 10px;
                  width: 75%;
                  color: #FFFFFF;
                  font-size: 3.6rem;
                  font-weight: 600;
                }
              }
            }

          } //.rightBox

        } //.row

      } //.boxes

    } //.inner

  } //#Sec02

}


@media screen and (min-width: 1660px) {
/* 1500px以上 */

.about {

  #Sec02 {

    .inner {

      .boxes {

        .row {

          .rightBox {

            &.row3 {
              .str3 {
                padding-right: 5% !important;
                width: 70% !important;
                text-align: center;
              }
            }

          } // .rightBox

        } //.row

      } //.boxes

    } //.inner

  } //#Sec02

} //.top

/* end 1500px以上 */
}


@media screen and (max-width: 1023px) {
/* 1023px以下 */

.about {

  #Sec01 {

    .inner {

      .catchBox {

        .str4 {
          font-size: 3.1vh;
          line-height: 4vh;
        }

      }

    }

  }

  #Sec02 {

    .inner {

      .boxes {

        .row {
          // display: block;
          flex-wrap: wrap;
          &.row3 {
            flex-direction: column-reverse;
          }

          .leftBox {
            width: 100%;

            &.row2 {
              display: none;
            }
          } // .leftBox

          .rightBox {
            width: 100%;

            &.row3 {
              .strBox{
                flex-direction: column;
                .arrow {
                  display: none;
                }
                .arrow-sp {
                  padding-top: 45px;
                  display: block;
                  width: 35px;
                  height: auto;
                }
                .dummy {
                  display: none;
                }
                .str3 {
                  width: 100%;
                  text-align: center;
                }
              }
            }
          } // .rightBox

        } //.row

      } //.boxes

    } //.inner

  } //#Sec02

} //.top

/* end 1023px以下 */
}


@media screen and (max-width: 768px) {
/* 768px以下 */

.about {

  #Sec01 {

    .inner {

      .catchBox {
        margin-bottom: 0px;

        video.video-pc {
          display: none;
        }
        video.video-sp {
          display: block;
          width: auto;
          height: auto;
          min-width: 100%;
          min-height: 100%;
          background-color: #000000;
          background-size: cover;
        }

        .filterOnVideo {
          min-height: 100%;
        }

        .str4 {
          bottom: 60px;
          padding: 0 15px;
          font-size: 2.6vh;
          font-weight: 600;
          line-height: 3.5vh;
        }

        .arrowBox-sp {
          display: flex;
          justify-content: center;
          position: absolute;
          bottom: 25px;
          left: 0;
          width: 100%;

          .arrow-sp {
            width: 35px;
            height: 17px;
          }
        }

      } //.catchBox

    } //.inner

  } //#Sec01

  #Sec02 {

    .inner {

      .boxes {

        .row {

          .leftBox {

          } //.leftBox

          .rightBox {
            &.row1 {
              .str1 {
                width: 83%;
                padding: 0;
                font-size: 2.6rem;
                line-height: 3.5rem;
              }
            }
            &.row2 {
              .bannerBox {
                flex-direction: column;
                justify-content: center;
                .dummy {
                  width: 0;
                  height: 30px;
                }
                .link-ios{
                  text-align: center;
                }
                .link-android{
                  text-align: center;
                }
              }
            }
            &.row3 {
              .strBox {
                .str3 {
                  padding: 0;
                  width: 83%;
                  font-size: 2.6rem;
                  line-height: 3.5rem;
                  text-align: center;
                }
              }
            }
          } //.rightBox

        } //.row

      } //.boxes

    } //.inner

  } //#Sec03

} //.about

/* end 768px以下 */
}

