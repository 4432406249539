
#modal-contentLink {
  display: none;
}

.modal-contentLink-custom {
 .modaal-container {
   //border-radius: 5%;
   max-width: 400px;
   width: 80%;

   background-color: #00000000;
   box-shadow: none;

   .modaal-content-container {
    padding: 10px 5px !important;

      .modal_contentLink_body {


        .upper {
          background-color: #FFFFFF;
          border-radius: 15px;

          .titleBox {
            padding: 10px 5px;
            display: flex;
            justify-content: center;
            .title {
              font-size: 13px;
              font-weight: 600;
            }
          } //.titleBox

        } //.upper

        .lower {
          margin-top: 20px;
          background-color: #FFFFFF;
          border-radius: 15px;
        } //.lower

        .btnBox {
          padding: 10px 5px;
          display: flex;
          justify-content: center;

          &.bt {
            border-top: solid 2px #F0F0F0;
          }

          .btn {
            display: block;
            border-radius: 0;
            width: 100%;
            font-size: 17px;
            font-weight: 500;

            &.btn-translate, &.btn-original {
              color: $pink;
            }
            &.btn-cancel {
              color: #979797;
            }

          }

        } //.btnBox

      } //.modal_share_body

    } //.modaal-content-container

  } //.modaal-container
} //.modal-share-custom
