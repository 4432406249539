
.rules{

  #Sec01{
    margin: 0 15px 10px 15px;

    .inner{
      position: relative;

      h1.page_title {
        padding-top: 30px;
        font-size: 1.8rem;
        font-weight: 600;
      }

      h2.heading {
        padding-top: 30px;
        font-size: 1.5rem;
        font-weight: 600;
      }

      .str {
        padding-top: 10px;
        font-size: 1.2rem;
      }


      ol.list_str{
        padding:0 0 0 2.0em;
        //margin:0;
        margin-top: 10px;

        li {
          font-size: 1.2rem;
          padding-top: 5px;
        }

        &.type1 {
          padding:0 0 0 1.5em;
          li {
            list-style-type:none;
            list-style-position:inside;
            counter-increment: cnt1;
          }
          li:before{
            display: marker;
            content: counter(cnt1) ". ";
            margin-left: -1.5em;
          }
        }
        &.type2 {
          padding:0 0 0 2.0em;
          li {
            list-style-type:none;
            list-style-position:inside;
            counter-increment: cnt2;
          }
          li:before{
            display: marker;
            content: "(" counter(cnt2) ") ";
            margin-left: -2.0rem;
          }
        }
        &.type3 {
          padding:0 0 0 1.5em;
          margin-top: 0;
          li {
            list-style-type:none;
            list-style-position:inside;
            counter-increment: cnt3;
          }
          li:before{
            display: marker;
            content: "・ ";
            margin-left: -1.5em;
          }
        }
      }


    } //.inner

  } //#Sec01


} //.login
