@charset "UTF-8";
/* base */
/*--------------------------------------------------------------------------
   reset
---------------------------------------------------------------------------*/
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong,
sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 0; }

article, aside, details, figcaption, figure, main, footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul, li {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

img {
  border: 0;
  vertical-align: top; }

em {
  font-style: italic; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0; }

input, select, textarea {
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: middle; }

input:focus, textarea:focus, select:focus {
  outline: none; }

/*--------------------------------------------------------------------------
   html
---------------------------------------------------------------------------*/
html {
  overflow-y: scroll;
  font-size: 62.5%; }

/*--------------------------------------------------------------------------
   body
---------------------------------------------------------------------------*/
body {
  font-family: "ヒラギノ角ゴ Pro W5","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
  color: #3E3A39;
  font-size: 1.3rem;
  letter-spacing: .05em;
  line-height: 1.5;
  color: #3E3A39;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background: #ffffff; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

h1 {
  font-size: 2.8rem; }

h2 {
  font-size: 2.4rem; }

h3 {
  font-size: 1.8rem; }

.adobe-font {
  font-family: reenie-beanie, sans-serif;
  font-weight: 500;
  font-style: normal; }

/*--------------------------------------------------------------------------
   base link
---------------------------------------------------------------------------*/
a {
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.7);
  color: #3E3A39;
  transition: 0.5s; }

a:link,
a:visited {
  text-decoration: none; }

a:hover {
  text-decoration: none;
  outline: none;
  opacity: 0.7;
  transition: 0.5s; }

a:active {
  outline: none; }

/*--------------------------------------------------------------------------
   Other
---------------------------------------------------------------------------*/
input:focus, input[type]:focus, .uneditable-input:focus {
  outline: 0;
  box-shadow: none;
  border: 0px solid #FFFFFF;
  background-color: #ffffff; }

/* clearfix
-----------------------------------------------------------------*/
.cf:before,
.cf:after {
  content: "";
  display: table; }

.cf:after {
  clear: both; }

.cf {
  zoom: 1; }

.sp {
  display: none !important; }

/* module */
/*--------------------------------------------------------------------------
   VARIABLE
---------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------
   MIXIN
---------------------------------------------------------------------------*/
.modaal-overlay {
  z-index: 10001 !important; }

.modaal-wrapper {
  z-index: 10002 !important; }

/*--------------------------------------------------------------------------
   Header
---------------------------------------------------------------------------*/
#Header .header-flex {
  position: sticky;
  margin-bottom: -38px;
  width: 100%;
  height: 38px;
  z-index: 10000;
  background-color: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1); }
  #Header .header-flex .header-wrapper {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    #Header .header-flex .header-wrapper .menu {
      width: inherit;
      height: 100%;
      align-items: center; }
      #Header .header-flex .header-wrapper .menu .open_btn {
        display: block;
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 100%;
        background: white;
        align-items: center;
        justify-content: center;
        transition: 0;
        z-index: 10001; }
        #Header .header-flex .header-wrapper .menu .open_btn img {
          margin: 10px 18px; }
      #Header .header-flex .header-wrapper .menu .close_btn {
        display: block;
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 100%;
        background: white;
        align-items: center;
        justify-content: center;
        transition: 0; }
        #Header .header-flex .header-wrapper .menu .close_btn img {
          height: 12px;
          width: 12px; }
    #Header .header-flex .header-wrapper .leftBox {
      display: flex;
      align-items: center;
      margin-left: 10%; }
      #Header .header-flex .header-wrapper .leftBox .logo .header-logo {
        margin-top: 2px; }
      #Header .header-flex .header-wrapper .leftBox .logo .button_min {
        height: 10px;
        width: 10px; }
    #Header .header-flex .header-wrapper .keywords {
      display: none; }
    #Header .header-flex .header-wrapper .widewrap {
      display: flex; }
      #Header .header-flex .header-wrapper .widewrap .nav {
        display: none; }
      #Header .header-flex .header-wrapper .widewrap .twitter {
        display: none; }
      #Header .header-flex .header-wrapper .widewrap .mirrorball {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0px 16px; }
        #Header .header-flex .header-wrapper .widewrap .mirrorball img {
          height: 20px;
          width: 20px; }

#Header .header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 38px;
  z-index: 10000;
  background-color: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1); }
  #Header .header-fixed .header-wrapper {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    #Header .header-fixed .header-wrapper .menu {
      width: inherit;
      height: 100%;
      align-items: center; }
      #Header .header-fixed .header-wrapper .menu .open_btn {
        display: block;
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 100%;
        background: white;
        align-items: center;
        justify-content: center;
        transition: 0;
        z-index: 10001; }
        #Header .header-fixed .header-wrapper .menu .open_btn img {
          margin: 10px 18px; }
      #Header .header-fixed .header-wrapper .menu .close_btn {
        display: block;
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 100%;
        background: white;
        align-items: center;
        justify-content: center;
        transition: 0; }
        #Header .header-fixed .header-wrapper .menu .close_btn img {
          height: 12px;
          width: 12px; }
    #Header .header-fixed .header-wrapper .leftBox {
      display: flex;
      align-items: center;
      margin-left: 10%; }
      #Header .header-fixed .header-wrapper .leftBox .logo .header-logo {
        margin-top: 2px; }
      #Header .header-fixed .header-wrapper .leftBox .logo .button_min {
        height: 10px;
        width: 10px; }
    #Header .header-fixed .header-wrapper .keywords {
      display: none; }
    #Header .header-fixed .header-wrapper .widewrap {
      display: flex; }
      #Header .header-fixed .header-wrapper .widewrap .nav {
        display: none; }
      #Header .header-fixed .header-wrapper .widewrap .twitter {
        display: none; }
      #Header .header-fixed .header-wrapper .widewrap .mirrorball {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0px 16px; }
        #Header .header-fixed .header-wrapper .widewrap .mirrorball img {
          height: 20px;
          width: 20px; }

@media screen and (max-width: 768px) {
  #Header .header-fixed .header-wrapper .leftBox, #Header .header-flex .header-wrapper .leftBox {
    width: 53vw; }
    #Header .header-fixed .header-wrapper .leftBox .logo, #Header .header-flex .header-wrapper .leftBox .logo {
      width: 200px; } }

@media screen and (min-width: 768px) {
  #Header .header-fixed .header-wrapper, #Header .header-flex .header-wrapper {
    margin: 0% 1%; }
    #Header .header-fixed .header-wrapper .menu .open_btn, #Header .header-flex .header-wrapper .menu .open_btn {
      left: 1%; }
    #Header .header-fixed .header-wrapper .menu .close_btn, #Header .header-flex .header-wrapper .menu .close_btn {
      left: 1%; }
    #Header .header-fixed .header-wrapper .keywords, #Header .header-flex .header-wrapper .keywords {
      margin-left: -9%;
      display: inherit;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
      color: #231F20; }
      #Header .header-fixed .header-wrapper .keywords .keyword, #Header .header-flex .header-wrapper .keywords .keyword {
        display: inherit;
        margin-left: 75px;
        padding: 0 10px; }
        #Header .header-fixed .header-wrapper .keywords .keyword:hover, #Header .header-flex .header-wrapper .keywords .keyword:hover {
          color: #231F20; }
    #Header .header-fixed .header-wrapper .widewrap, #Header .header-flex .header-wrapper .widewrap {
      display: flex;
      justify-content: center;
      align-items: center; }
      #Header .header-fixed .header-wrapper .widewrap .nav, #Header .header-flex .header-wrapper .widewrap .nav {
        display: inherit;
        cursor: pointer; }
      #Header .header-fixed .header-wrapper .widewrap .nav:hover img, #Header .header-flex .header-wrapper .widewrap .nav:hover img {
        margin-left: 10px;
        margin-top: 2px;
        transform: rotate(-180deg);
        transition: .3s; }
      #Header .header-fixed .header-wrapper .widewrap .nav ul, #Header .header-flex .header-wrapper .widewrap .nav ul {
        font-size: 0; }
      #Header .header-fixed .header-wrapper .widewrap .nav ul li, #Header .header-flex .header-wrapper .widewrap .nav ul li {
        display: inline-block;
        width: 180px;
        position: relative;
        background: rgba(255, 255, 255, 0.95); }
      #Header .header-fixed .header-wrapper .widewrap .nav ul li a, #Header .header-flex .header-wrapper .widewrap .nav ul li a {
        text-align: center;
        display: block;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        color: #231F20; }
        #Header .header-fixed .header-wrapper .widewrap .nav ul li a img, #Header .header-flex .header-wrapper .widewrap .nav ul li a img {
          margin-left: 10px;
          margin-top: 2px;
          transition: .3s; }
      #Header .header-fixed .header-wrapper .widewrap .nav ul li a:hover, #Header .header-flex .header-wrapper .widewrap .nav ul li a:hover {
        text-align: center;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        color: #231F20; }
      #Header .header-fixed .header-wrapper .widewrap .nav li ul, #Header .header-flex .header-wrapper .widewrap .nav li ul {
        position: absolute;
        z-index: 9999;
        width: 100%; }
      #Header .header-fixed .header-wrapper .widewrap .nav li ul li, #Header .header-flex .header-wrapper .widewrap .nav li ul li {
        display: block;
        width: 100%; }
      #Header .header-fixed .header-wrapper .widewrap .nav li ul li a, #Header .header-flex .header-wrapper .widewrap .nav li ul li a {
        height: 32px;
        padding: 8px 0px 5px 0px;
        margin: 0px 18px;
        border-bottom: 1px solid #F7F6EB; }
      #Header .header-fixed .header-wrapper .widewrap .nav > ul > li > ul > li.dummy > a, #Header .header-flex .header-wrapper .widewrap .nav > ul > li > ul > li.dummy > a {
        height: 19px;
        border-bottom: none; }
      #Header .header-fixed .header-wrapper .widewrap .nav li ul li, #Header .header-flex .header-wrapper .widewrap .nav li ul li {
        overflow: hidden;
        height: 0;
        transition: .3s; }
      #Header .header-fixed .header-wrapper .widewrap .nav li:hover ul li, #Header .header-flex .header-wrapper .widewrap .nav li:hover ul li {
        overflow: visible;
        height: 32px; }
      #Header .header-fixed .header-wrapper .widewrap .nav li:hover ul li.dummy, #Header .header-flex .header-wrapper .widewrap .nav li:hover ul li.dummy {
        overflow: visible;
        height: 19px; }
      #Header .header-fixed .header-wrapper .widewrap .twitter, #Header .header-flex .header-wrapper .widewrap .twitter {
        display: inherit; }
        #Header .header-fixed .header-wrapper .widewrap .twitter img, #Header .header-flex .header-wrapper .widewrap .twitter img {
          height: 18px;
          width: 18px; } }

@media screen and (min-width: 1280px) {
  #Header .header-fixed .header-wrapper, #Header .header-flex .header-wrapper {
    margin: 0% 5%; }
    #Header .header-fixed .header-wrapper .menu .open_btn, #Header .header-flex .header-wrapper .menu .open_btn {
      left: 5%; }
    #Header .header-fixed .header-wrapper .menu .close_btn, #Header .header-flex .header-wrapper .menu .close_btn {
      left: 5%; } }

/*--------------------------------------------------------------------------
   MOTION関連
---------------------------------------------------------------------------*/
.motion {
  margin-top: -160px; }

.appimg {
  margin-top: -160px; }

/*--------------------------------------------------------------------------
   RANK関連
---------------------------------------------------------------------------*/
#loadedRank {
  display: block;
  padding: 14px 3px 22px 3px; }
  #loadedRank .rankLine {
    margin: 5px 23px 0px 23px;
    min-height: 75px;
    border-bottom: 1px solid #F7F6EB; }
    #loadedRank .rankLine .line-head {
      display: flex;
      align-items: center; }
      #loadedRank .rankLine .line-head img {
        width: 15px;
        height: 12px;
        margin-right: 7px; }
      #loadedRank .rankLine .line-head .linenum {
        margin-right: 14px;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #CDCDCD; }
      #loadedRank .rankLine .line-head .postdate {
        font-weight: bold;
        font-size: 10px;
        line-height: 15px;
        color: #8E8E93;
        white-space: nowrap; }
    #loadedRank .rankLine .rank-inner {
      display: flex;
      align-items: center; }
      #loadedRank .rankLine .rank-inner .rank-img {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        width: 46px;
        background-color: #222222;
        overflow: hidden; }
        #loadedRank .rankLine .rank-inner .rank-img img {
          object-fit: cover;
          height: 36px;
          width: 46px; }
      #loadedRank .rankLine .rank-inner .rank-title {
        word-wrap: break-word;
        display: -webkit-box;
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-left: 5px;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        color: #231F20;
        width: 100%; }

#rankforwide {
  display: none; }

@media screen and (min-width: 1280px) {
  #loadedRank {
    display: none; }
  #rankforwide {
    display: block;
    width: 367px;
    margin-top: 45px;
    padding: 14px 3px 22px 3px; }
    #rankforwide .rankLine {
      margin: 5px 23px 0px 23px;
      min-height: 75px;
      border-bottom: 1px solid #F7F6EB; }
      #rankforwide .rankLine .line-head {
        display: flex;
        align-items: center; }
        #rankforwide .rankLine .line-head img {
          width: 15px;
          height: 12px;
          margin-right: 7px; }
        #rankforwide .rankLine .line-head .linenum {
          margin-right: 14px;
          font-weight: bold;
          font-size: 20px;
          line-height: 30px;
          color: #CDCDCD; }
        #rankforwide .rankLine .line-head .postdate {
          font-weight: bold;
          font-size: 10px;
          line-height: 15px;
          color: #8E8E93;
          white-space: nowrap; }
      #rankforwide .rankLine .rank-inner {
        display: flex;
        align-items: center; }
        #rankforwide .rankLine .rank-inner .rank-img {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 36px;
          width: 46px;
          background-color: #222222;
          overflow: hidden; }
          #rankforwide .rankLine .rank-inner .rank-img img {
            object-fit: cover;
            height: 36px;
            width: 46px; }
        #rankforwide .rankLine .rank-inner .rank-title {
          word-wrap: break-word;
          display: -webkit-box;
          /* autoprefixer: off */
          -webkit-box-orient: vertical;
          /* autoprefixer: on */
          -webkit-line-clamp: 2;
          overflow: hidden;
          margin-left: 5px;
          font-weight: bold;
          font-size: 14px;
          line-height: 21px;
          color: #231F20;
          width: 100%; } }

/*--------------------------------------------------------------------------
   DETAIL関連
---------------------------------------------------------------------------*/
@media screen and (max-width: 648px) {
  .detail .link-noogp {
    width: 90vw; } }

.detail .link-noogp {
  margin: 30px 0;
  padding: 0 30px 5px 30px;
  display: block;
  background-color: #FF233E;
  cursor: pointer; }
  .detail .link-noogp .detailBox {
    display: flex;
    justify-content: center; }
    .detail .link-noogp .detailBox .link-detail-icon {
      margin-right: 15px; }
    .detail .link-noogp .detailBox .link-detail-str {
      text-align: center;
      font-size: 17px;
      font-weight: 600;
      color: #FFFFFF; }
  .detail .link-noogp .link-url {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 9px;
    font-weight: 300;
    color: #FFFFFF; }

.detail .content.ads {
  justify-content: center; }
  .detail .content.ads.center {
    border-top: 1px solid #D8D8D8;
    margin-top: 30px; }

.detail #RankAdlinkArea {
  display: none; }

.detail .widewrap {
  display: flex; }

.detail #loadedArea .content {
  margin: 0px 23px 0px 23px;
  border-bottom: 1px solid #F7F6EB;
  padding-bottom: 10px; }
  .detail #loadedArea .content .contenthead {
    display: flex;
    margin: 10px 3px 0px 3px;
    align-items: center;
    width: 100%; }
    .detail #loadedArea .content .contenthead .category {
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 15px;
      color: #231F20;
      margin-right: 9px;
      white-space: nowrap; }
    .detail #loadedArea .content .contenthead .postdate {
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 15px;
      color: #8E8E93;
      white-space: nowrap; }
    .detail #loadedArea .content .contenthead .reactioncountry {
      display: flex;
      flex-wrap: wrap;
      margin: 0px 0px 0px 14px;
      max-width: 220px; }
      .detail #loadedArea .content .contenthead .reactioncountry .flag {
        width: auto;
        height: 12px;
        margin: 0px 9px 3px 0px; }
        .detail #loadedArea .content .contenthead .reactioncountry .flag img {
          width: auto;
          height: 12px;
          border: 0.5px solid #979797;
          box-sizing: border-box; }
    .detail #loadedArea .content .contenthead .share {
      margin-left: auto;
      padding-bottom: 3px; }
      .detail #loadedArea .content .contenthead .share img {
        width: 20px; }
  .detail #loadedArea .content .contentinner {
    border-bottom: none;
    margin: 8px 4px 0px 4px; }
    .detail #loadedArea .content .contentinner.ads {
      justify-content: center; }
    .detail #loadedArea .content .contentinner .cnt-img {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 256px;
      min-width: 320px;
      max-width: 420px;
      background-color: #222222;
      overflow: hidden;
      margin: auto; }
      .detail #loadedArea .content .contentinner .cnt-img img {
        object-fit: cover;
        width: 100%; }
      .detail #loadedArea .content .contentinner .cnt-img .link-url {
        position: absolute;
        width: 95%;
        bottom: 5px;
        left: 10px;
        color: #FFFFFF;
        font-size: 9px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
    .detail #loadedArea .content .contentinner .contenttitle {
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      line-height: 25px;
      color: #231F20;
      margin-top: 3px; }
  .detail #loadedArea .content .contentfooter {
    margin: 9px 4px 0px 4px; }
    .detail #loadedArea .content .contentfooter .translateArea p.originalComment {
      display: none;
      font-size: 10px; }
      .detail #loadedArea .content .contentfooter .translateArea p.originalComment.show {
        display: block; }
    .detail #loadedArea .content .contentfooter .translateArea .btnShowOriginal {
      color: #FF233E;
      font-size: 10px;
      font-weight: 600; }

.detail #loadedArea .comment {
  margin: 7px 27px 0px 27px;
  border-bottom: 1px solid #F7F6EB;
  padding-bottom: 11px; }
  .detail #loadedArea .comment .commenthead {
    margin: 10px 3px 0px 3px;
    display: flex; }
    .detail #loadedArea .comment .commenthead .commenthead-left {
      display: flex;
      justify-content: center;
      align-items: center; }
      .detail #loadedArea .comment .commenthead .commenthead-left .flag {
        width: 20px;
        height: 12px;
        margin: 0px 9px 3px 0px; }
        .detail #loadedArea .comment .commenthead .commenthead-left .flag img {
          width: auto;
          height: 12px;
          border: 0.5px solid #979797;
          box-sizing: border-box; }
    .detail #loadedArea .comment .commenthead .commenthead-right {
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 15px;
      color: #8E8E93; }
  .detail #loadedArea .comment .commentinner {
    margin-top: 10px; }
    .detail #loadedArea .comment .commentinner .cmt-img {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      margin-left: auto;
      margin-right: auto;
      height: 256px;
      min-width: 320px;
      max-width: 420px;
      background-color: #222222;
      overflow: hidden; }
      .detail #loadedArea .comment .commentinner .cmt-img img {
        object-fit: cover;
        width: 100%; }
      .detail #loadedArea .comment .commentinner .cmt-img .link-url {
        position: absolute;
        width: 95%;
        bottom: 5px;
        left: 10px;
        color: #FFFFFF;
        font-size: 9px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
  .detail #loadedArea .comment .commentComment {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    color: #231F20; }

.detail #loadedArea .commentfooter {
  margin-top: 10px; }
  .detail #loadedArea .commentfooter .translateArea p.originalComment {
    display: none;
    font-size: 10px; }
    .detail #loadedArea .commentfooter .translateArea p.originalComment.show {
      display: block; }
  .detail #loadedArea .commentfooter .translateArea .btnShowOriginal {
    color: #FF233E;
    font-size: 10px;
    font-weight: 600; }

.detail .bannerBox_U {
  display: block;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 47px; }
  .detail .bannerBox_U .link-ios {
    display: block; }
    .detail .bannerBox_U .link-ios .banner-ios {
      height: 40px; }
  .detail .bannerBox_U .dummy {
    height: 17px; }
  .detail .bannerBox_U .link-android {
    display: block; }
    .detail .bannerBox_U .link-android .banner-android {
      height: 40px; }

.detail .app_text_U {
  display: block;
  margin-top: 28px;
  margin-left: 28px;
  margin-right: 28px;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #231F20; }

@media screen and (min-width: 768px) {
  .detail .widewrap {
    justify-content: center; }
  .detail #loadedArea .content .contenthead .category {
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 25px;
    color: #231F20;
    margin-right: 9px;
    white-space: nowrap; }
  .detail #loadedArea .content .contenthead .postdate {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #8E8E93;
    white-space: nowrap; }
  .detail #loadedArea .content .contenthead .reactioncountry {
    flex-wrap: nowrap;
    width: auto; }
  .detail #loadedArea .content .contenthead .share {
    margin-left: auto;
    margin-right: 10px;
    padding-bottom: 3px; }
    .detail #loadedArea .content .contenthead .share img {
      width: 25px; }
  .detail #loadedArea .content .contentinner.ads {
    justify-content: center; }
  .detail #loadedArea .content .contentinner .contenttitle {
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 36px;
    color: #231F20;
    margin-top: 20px;
    margin-bottom: 20px; }
  .detail #loadedArea .content .contentfooter .translateArea p.originalComment {
    display: none;
    font-size: 12px; }
    .detail #loadedArea .content .contentfooter .translateArea p.originalComment.show {
      display: block; }
  .detail #loadedArea .content .contentfooter .translateArea .btnShowOriginal {
    color: #FF233E;
    font-size: 12px;
    font-weight: 600; }
  .detail #loadedArea .comment .commenthead .commenthead-left .flag {
    width: 30px;
    height: 16px;
    margin: 0px 12px 3px 0px; }
    .detail #loadedArea .comment .commenthead .commenthead-left .flag img {
      width: auto;
      height: 16px;
      border: 0.5px solid #979797;
      box-sizing: border-box; }
  .detail #loadedArea .comment .commenthead .commenthead-right {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: #8E8E93; }
  .detail #loadedArea .comment .commentinner .commentComment {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 30px;
    color: #231F20; }
  .detail #loadedArea .comment .commentfooter .translateArea p.originalComment {
    display: none;
    font-size: 12px; }
    .detail #loadedArea .comment .commentfooter .translateArea p.originalComment.show {
      display: block; }
  .detail #loadedArea .comment .commentfooter .translateArea .btnShowOriginal {
    color: #FF233E;
    font-size: 12px;
    font-weight: 600; } }

@media screen and (min-width: 1280px) {
  .detail {
    margin: 0px 20px 0px 40px; }
    .detail .widewrap {
      margin: 0px 10px 0px 10px;
      justify-content: center; }
    .detail .bannerBox_U, .detail .app_text_U {
      display: none; }
    .detail #RankAdlinkArea {
      display: block;
      margin-left: 23px;
      display: block;
      width: 367px; }
      .detail #RankAdlinkArea * {
        display: block; }
      .detail #RankAdlinkArea #rankforwide {
        margin-top: 0px;
        padding: 3px 3px 22px 3px; }
      .detail #RankAdlinkArea .sidebar {
        position: sticky;
        top: 0; }
        .detail #RankAdlinkArea .sidebar .content {
          margin: 0px 0px 0px 0px;
          padding: 0px 37px 13px 37px; }
        .detail #RankAdlinkArea .sidebar .bannerBox {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 47px; }
          .detail #RankAdlinkArea .sidebar .bannerBox .link-ios {
            display: block; }
            .detail #RankAdlinkArea .sidebar .bannerBox .link-ios .banner-ios {
              height: 40px; }
          .detail #RankAdlinkArea .sidebar .bannerBox .dummy {
            height: 17px; }
          .detail #RankAdlinkArea .sidebar .bannerBox .link-android {
            display: block; }
            .detail #RankAdlinkArea .sidebar .bannerBox .link-android .banner-android {
              height: 40px; }
        .detail #RankAdlinkArea .sidebar .app_text {
          margin-top: 28px;
          font-style: normal;
          font-weight: bold;
          font-size: 15px;
          line-height: 22px;
          text-align: center;
          color: #231F20; }
        .detail #RankAdlinkArea .sidebar .sidelink {
          display: flex;
          flex-direction: column;
          margin: 40px 0px 0px 23px; }
          .detail #RankAdlinkArea .sidebar .sidelink a {
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 30px;
            color: #FF233E; } }

/*--------------------------------------------------------------------------
   CONTENTS関連
---------------------------------------------------------------------------*/
#adlinkArea {
  display: none; }

.cntrankwrap {
  display: flex; }
  .cntrankwrap .topcnt .content {
    border-bottom: 1px solid #D8D8D8; }

.content {
  margin: 0px 13px 0px 13px;
  padding-bottom: 13px;
  background: #FFFFFF;
  border-top: 1px solid #D8D8D8; }
  .content.ads {
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 15px;
    color: #8E8E93; }
    .content.ads.side {
      border-bottom: 1px solid #D8D8D8; }
    .content.ads.center {
      border-top: none; }
    .content.ads .contenthead {
      margin: 18px 0px 8px 1px; }
    .content.ads .contentinner {
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: none; }
      .content.ads .contentinner.ads {
        justify-content: center; }
  .content .contentwrap .cnt-img {
    display: none; }
  .content .contentwrap .widespace {
    display: none; }
  .content .contentwrap .contenthead {
    display: flex;
    margin: 10px 3px 0px 3px;
    align-items: center; }
    .content .contentwrap .contenthead .category {
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 15px;
      color: #231F20;
      margin-right: 9px;
      white-space: nowrap; }
    .content .contentwrap .contenthead .postdate {
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 15px;
      color: #8E8E93;
      white-space: nowrap; }
    .content .contentwrap .contenthead .reactioncountry {
      display: flex;
      flex-wrap: wrap;
      margin: 0px 0px 0px 14px;
      max-width: 220px; }
      .content .contentwrap .contenthead .reactioncountry .flag {
        width: 20px;
        height: 12px;
        margin: 0px 9px 3px 0px; }
        .content .contentwrap .contenthead .reactioncountry .flag img {
          width: auto;
          height: 12px;
          border: 0.5px solid #979797;
          box-sizing: border-box; }
    .content .contentwrap .contenthead .share {
      margin-left: auto;
      padding-bottom: 3px; }
      .content .contentwrap .contenthead .share img {
        width: 20px; }
  .content .contentwrap .contentinner {
    margin: 8px 14px 0px 14px;
    padding-bottom: 8px;
    border-bottom: 1px solid #F7F6EB; }
    .content .contentwrap .contentinner.ads {
      justify-content: center; }
    .content .contentwrap .contentinner .cnt-img {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 170px;
      min-width: 320px;
      background-color: #222222;
      overflow: hidden; }
      .content .contentwrap .contentinner .cnt-img img {
        width: 100%;
        height: auto; }
    .content .contentwrap .contentinner .contenttitle {
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      line-height: 25px;
      color: #231F20;
      margin-top: 7px;
      word-wrap: break-word;
      display: -webkit-box;
      /* autoprefixer: off */
      -webkit-box-orient: vertical;
      /* autoprefixer: on */
      -webkit-line-clamp: 6;
      overflow: hidden; }
  .content .contentfooter {
    margin: 9px 10px 0px 17px; }
    .content .contentfooter .footerupper {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      color: #979797; }
      .content .contentfooter .footerupper .topflag {
        width: 20px;
        height: 12px;
        margin: 0px 5px 0px 0px; }
        .content .contentfooter .footerupper .topflag img {
          width: auto;
          height: 12px;
          border: 0.5px solid #979797;
          box-sizing: border-box; }
      .content .contentfooter .footerupper .topfrom {
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #979797; }
    .content .contentfooter .topcomment {
      margin-top: 9px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #231F20;
      word-wrap: break-word;
      display: -webkit-box;
      /* autoprefixer: off */
      -webkit-box-orient: vertical;
      /* autoprefixer: on */
      -webkit-line-clamp: 3;
      overflow: hidden; }
  .content.dummy {
    height: 20px;
    opacity: 0; }

@media screen and (min-width: 768px) {
  #adlinkArea {
    display: none; }
  .cntrankwrap .topcnt {
    width: 100%; }
  .content {
    margin: 0px 37px 0px 37px;
    padding-bottom: 13px;
    background: #FFFFFF;
    border-top: 1px solid #D8D8D8; }
    .content.ads .contenthead {
      margin: 10px 0px 13px 1px; }
    .content.ads .contentinner {
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: none; }
      .content.ads .contentinner.ads {
        justify-content: center; }
    .content .contentwrap {
      display: flex; }
      .content .contentwrap .cnt-img {
        flex: none;
        margin-top: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 320px;
        height: 256px;
        background-color: #222222;
        overflow: hidden; }
        .content .contentwrap .cnt-img img {
          width: 320px;
          height: 256px;
          object-fit: cover; }
      .content .contentwrap .contentwidewrap {
        width: 100%; }
        .content .contentwrap .contentwidewrap .contenthead {
          display: flex;
          margin: 10px 16px 0px 12px;
          align-items: center; }
          .content .contentwrap .contentwidewrap .contenthead .reactioncountry {
            flex-wrap: nowrap;
            width: auto; }
        .content .contentwrap .contentwidewrap .contentinner {
          border-bottom: none;
          display: flex;
          flex-direction: column;
          margin-right: 16px;
          margin-left: 12px;
          height: 100%; }
          .content .contentwrap .contentwidewrap .contentinner .contenttitle {
            font-style: normal;
            font-weight: bold;
            font-size: 19px;
            line-height: 36px;
            color: #231F20;
            margin-top: 7px;
            word-wrap: break-word;
            display: -webkit-box;
            /* autoprefixer: off */
            -webkit-box-orient: vertical;
            /* autoprefixer: on */
            -webkit-line-clamp: 6;
            overflow: hidden; }
          .content .contentwrap .contentwidewrap .contentinner.ads {
            justify-content: center; }
          .content .contentwrap .contentwidewrap .contentinner.fw {
            height: 80%; }
          .content .contentwrap .contentwidewrap .contentinner .cnt-img {
            display: none; }
    .content .widespace {
      margin-left: 10px;
      margin-right: 10px;
      border-bottom: 1px solid #F7F6EB;
      height: 13px;
      background-color: #FFFFFF;
      display: block; }
    .content .contentfooter {
      margin: 9px 16px 0px 16px; }
      .content .contentfooter .topcomment {
        margin-top: 9px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #231F20;
        word-wrap: break-word;
        display: -webkit-box;
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
        -webkit-line-clamp: 3;
        overflow: hidden; }
    .content.dummy {
      height: 20px;
      opacity: 0; } }

@media screen and (min-width: 1280px) {
  .cntrankwrap .topcnt {
    width: 68%; }
  #Sec02 {
    width: auto; }
    #Sec02 .inner .cntlinkwrap {
      display: flex; }
      #Sec02 .inner .cntlinkwrap #adlinkArea {
        margin-left: 23px;
        margin-right: 30px;
        display: block;
        width: 367px; }
        #Sec02 .inner .cntlinkwrap #adlinkArea .sidebar {
          position: sticky;
          top: 0; }
          #Sec02 .inner .cntlinkwrap #adlinkArea .sidebar .content {
            margin: 0px 0px 0px 0px;
            padding: 0px 37px 13px 37px; }
          #Sec02 .inner .cntlinkwrap #adlinkArea .sidebar .bannerBox {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 47px; }
            #Sec02 .inner .cntlinkwrap #adlinkArea .sidebar .bannerBox .link-ios {
              display: block; }
              #Sec02 .inner .cntlinkwrap #adlinkArea .sidebar .bannerBox .link-ios .banner-ios {
                height: 40px; }
            #Sec02 .inner .cntlinkwrap #adlinkArea .sidebar .bannerBox .dummy {
              height: 17px; }
            #Sec02 .inner .cntlinkwrap #adlinkArea .sidebar .bannerBox .link-android {
              display: block; }
              #Sec02 .inner .cntlinkwrap #adlinkArea .sidebar .bannerBox .link-android .banner-android {
                height: 40px; }
          #Sec02 .inner .cntlinkwrap #adlinkArea .sidebar .app_text {
            margin-top: 28px;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 22px;
            text-align: center;
            color: #231F20; }
          #Sec02 .inner .cntlinkwrap #adlinkArea .sidebar .sidelink {
            display: flex;
            flex-direction: column;
            margin: 40px 0px 0px 23px; }
            #Sec02 .inner .cntlinkwrap #adlinkArea .sidebar .sidelink a {
              font-style: normal;
              font-weight: normal;
              font-size: 15px;
              line-height: 30px;
              color: #FF233E; } }

/*--------------------------------------------------------------------------
   ロードスピナー
---------------------------------------------------------------------------*/
.loader {
  color: #000000;
  font-size: 10px;
  margin: 200px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0); }

@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0; }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em; }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0; }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em; } }

@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0; }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em; }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0; }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em; } }

/*--------------------------------------------------------------------------
   Footer
---------------------------------------------------------------------------*/
/* Main
-----------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  /* 768pxまでの幅の場合に適応される */
  /*--------------------------------------------------------------------------
     Header
  ---------------------------------------------------------------------------*/
  #Header .global_navi {
    display: block; }
    #Header .global_navi .global_navi_inner {
      width: 100%;
      margin: 0 auto; }
  /*--------------------------------------------------------------------------
     Content
  ---------------------------------------------------------------------------*/
  /* Main
-----------------------------------------------------------------*/
  /* media-screen(768) */ }

#calenderOverlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 10000; }

#modal-calender, #modal-calender-pulldown {
  display: none;
  position: relative;
  width: 80%;
  max-width: 500px;
  min-width: 320px;
  position: fixed;
  background-color: #FFF;
  z-index: 10001;
  padding: 5px;
  overflow: hidden;
  padding: 20px 20px 40px 20px; }
  #modal-calender .btnBox img, #modal-calender-pulldown .btnBox img {
    height: 22px;
    width: 22px; }
  #modal-calender .btnBox.right, #modal-calender-pulldown .btnBox.right {
    margin-top: 30px;
    display: flex; }
  #modal-calender .btnBox .btnOK, #modal-calender-pulldown .btnBox .btnOK {
    margin-left: auto;
    display: block;
    padding: 5px 10px;
    border: 1px solid #FF233E;
    background: #FF233E;
    color: #FFFFFF;
    font-size: 1.8rem; }
  #modal-calender .pulldownArea, #modal-calender-pulldown .pulldownArea {
    margin-top: 30px; }
    #modal-calender .pulldownArea .label, #modal-calender-pulldown .pulldownArea .label {
      margin: 0;
      padding-left: 0;
      color: #8E8E93;
      font-weight: 600;
      font-size: 1.8rem; }
    #modal-calender .pulldownArea .pulldownEnd, #modal-calender-pulldown .pulldownArea .pulldownEnd {
      margin-top: 30px; }
    #modal-calender .pulldownArea .pulldownBox, #modal-calender-pulldown .pulldownArea .pulldownBox {
      margin-top: 10px;
      font-size: 1.8rem;
      display: flex;
      justify-content: center;
      align-items: center; }
      #modal-calender .pulldownArea .pulldownBox .str, #modal-calender-pulldown .pulldownArea .pulldownBox .str {
        padding-left: 7px;
        padding-right: 7px; }
      #modal-calender .pulldownArea .pulldownBox select, #modal-calender-pulldown .pulldownArea .pulldownBox select {
        font-size: 1.8rem;
        background-color: white;
        border: thin solid #FF233E;
        border-radius: 4px;
        display: inline-block;
        font: inherit;
        line-height: 1.5em;
        padding: 0.5em 1em 0.5em 1em;
        margin: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none; }
  #modal-calender .headBox, #modal-calender-pulldown .headBox {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    #modal-calender .headBox .centerMonth, #modal-calender-pulldown .headBox .centerMonth {
      display: flex;
      justify-content: center;
      align-items: flex-end; }
      #modal-calender .headBox .centerMonth .monthNum, #modal-calender-pulldown .headBox .centerMonth .monthNum {
        font-size: 2.4rem;
        padding-right: 10px; }
      #modal-calender .headBox .centerMonth .monthEng, #modal-calender-pulldown .headBox .centerMonth .monthEng {
        font-size: 1.3rem; }
  #modal-calender ul.calenderList, #modal-calender-pulldown ul.calenderList {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    #modal-calender ul.calenderList li, #modal-calender-pulldown ul.calenderList li {
      width: 14.2%;
      text-align: center;
      font-size: 1.8rem;
      padding-top: 5px;
      padding-bottom: 5px;
      display: flex;
      justify-content: center;
      align-items: center; }
      #modal-calender ul.calenderList li.head, #modal-calender-pulldown ul.calenderList li.head {
        font-weight: 600; }
      #modal-calender ul.calenderList li a.today, #modal-calender-pulldown ul.calenderList li a.today {
        display: block;
        color: #FF233E; }
      #modal-calender ul.calenderList li a.before, #modal-calender ul.calenderList li a.after, #modal-calender-pulldown ul.calenderList li a.before, #modal-calender-pulldown ul.calenderList li a.after {
        display: block;
        color: #000000; }
        #modal-calender ul.calenderList li a.before.invalid, #modal-calender ul.calenderList li a.after.invalid, #modal-calender-pulldown ul.calenderList li a.before.invalid, #modal-calender-pulldown ul.calenderList li a.after.invalid {
          color: #8E8E93;
          pointer-events: none; }
      #modal-calender ul.calenderList li a.other, #modal-calender-pulldown ul.calenderList li a.other {
        display: block;
        color: #8E8E93;
        pointer-events: none; }
      #modal-calender ul.calenderList li a.selected, #modal-calender-pulldown ul.calenderList li a.selected {
        width: 27px;
        height: 27px;
        display: block;
        background-color: #FF233E;
        color: #FFFFFF;
        border-radius: 50%; }
  #modal-calender .selectAllBtn, #modal-calender-pulldown .selectAllBtn {
    display: none;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #FF233E;
    width: 63px;
    height: 26px;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: #FF233E; }
    #modal-calender .selectAllBtn.selected, #modal-calender-pulldown .selectAllBtn.selected {
      background-color: #FF233E;
      color: #ffffff; }
  #modal-calender #calenderDummy, #modal-calender-pulldown #calenderDummy {
    position: relative; }
  #modal-calender #calenderNow, #modal-calender-pulldown #calenderNow {
    position: absolute;
    top: 55px;
    left: 0;
    width: 100%;
    padding: 0 20px;
    opacity: 1.0; }
  #modal-calender #calenderPrev, #modal-calender-pulldown #calenderPrev {
    position: absolute;
    top: 55px;
    left: -100%;
    width: 100%;
    padding: 0 20px;
    opacity: 0; }
  #modal-calender #calenderNext, #modal-calender-pulldown #calenderNext {
    position: absolute;
    top: 55px;
    left: 100%;
    width: 100%;
    padding: 0 20px;
    opacity: 0; }

#modal-share {
  display: none; }

.modal-share-custom .modaal-container {
  max-width: 400px;
  width: 80%; }
  .modal-share-custom .modaal-container .modaal-content-container {
    padding: 10px 5px !important; }
    .modal-share-custom .modaal-container .modaal-content-container .modal_share_body .btnBox {
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: center; }
      .modal-share-custom .modaal-container .modaal-content-container .modal_share_body .btnBox .btn {
        display: block;
        border-radius: 0;
        width: 90%;
        font-size: 1.6rem; }
        .modal-share-custom .modaal-container .modaal-content-container .modal_share_body .btnBox .btn.btn-twitter {
          background: #48a6f7;
          border: 1px solid #48a6f7;
          color: #FFFFFF; }
        .modal-share-custom .modaal-container .modaal-content-container .modal_share_body .btnBox .btn.btn-fb {
          background: #4267b2;
          border: 1px solid #4267b2;
          color: #FFFFFF; }
        .modal-share-custom .modaal-container .modaal-content-container .modal_share_body .btnBox .btn.btn-line {
          background: #54b635;
          border: 1px solid #54b635;
          color: #FFFFFF; }
        .modal-share-custom .modaal-container .modaal-content-container .modal_share_body .btnBox .btn.btn-clipboard {
          background: #D8D8D8;
          border: 1px solid #D8D8D8;
          color: #000000; }

#videoOverlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  display: none;
  z-index: 10000; }

#modal-video {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #FFF;
  z-index: 10001;
  overflow: hidden; }
  #modal-video .videoWrapper {
    display: flex;
    justify-content: center; }
  #modal-video .videoinner {
    position: relative; }
    #modal-video .videoinner .zoomdown {
      position: absolute;
      top: 5px;
      right: 5px; }
      #modal-video .videoinner .zoomdown img {
        margin: 0px;
        height: 30px;
        width: 30px; }

#modal-motion {
  width: auto;
  height: 100vh; }

#introOverlay {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000; }

#modal-intro {
  display: none;
  width: 300px;
  height: 470px;
  position: fixed;
  padding: 20px;
  background-color: #FFF;
  z-index: 10001;
  overflow: hidden; }
  #modal-intro img {
    margin-top: 10px;
    height: 75px; }
  #modal-intro .head {
    font-size: 24px;
    text-align: center;
    color: #8E8E93; }
  #modal-intro .text {
    margin-top: 10px;
    font-size: 18px;
    line-height: 25px; }
  #modal-intro .btnBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px; }
    #modal-intro .btnBox .btnClose {
      width: 190px;
      line-height: 35px;
      text-align: center;
      font-size: 14px;
      margin: 0px 20px;
      width: 100%;
      color: #FF233E;
      border-radius: 0px;
      border: solid 1px #FF233E;
      background: #FFFFFF; }

#imageOverlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  display: none;
  z-index: 10000; }

#modal-image {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #FFF;
  z-index: 10001;
  overflow: hidden; }
  #modal-image .imgWrapper {
    display: flex;
    justify-content: center; }
  #modal-image .imginner {
    position: relative; }
    #modal-image .imginner .zoomdown {
      position: absolute;
      top: 5px;
      right: 5px; }
      #modal-image .imginner .zoomdown img {
        margin: 0px;
        height: 30px;
        width: 30px; }

#modal-appimage {
  width: auto;
  height: 100vh; }

#tagListOverlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  display: none;
  z-index: 10000; }

#modal-tagList {
  display: none;
  position: fixed;
  min-width: 320px;
  width: 80%;
  max-height: 80%;
  background-color: #FFF;
  z-index: 10001;
  overflow-y: scroll; }
  #modal-tagList .header {
    background-color: #FFF; }
    #modal-tagList .header .closeBtnBox {
      margin-top: 18px;
      margin-left: 13px; }
      #modal-tagList .header .closeBtnBox img {
        height: 22px; }
    #modal-tagList .header .headBox .head {
      margin-top: 15px;
      margin-left: 37px;
      font-size: 24px;
      color: #8E8E93; }
  #modal-tagList #modalTagList {
    display: flex;
    flex-wrap: wrap;
    margin: 18px 15px 20px 0px; }
    #modal-tagList #modalTagList .tagCell {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 10px;
      margin: 0px 0px 9px 15px;
      width: auto;
      height: auto;
      background-color: #fff1f0;
      color: #FF0000;
      font-weight: 600;
      font-size: 14px;
      line-height: 30px; }
      #modal-tagList #modalTagList .tagCell img {
        margin-right: 5px; }

#tag_bar {
  display: none;
  position: fixed;
  bottom: 0;
  height: 55px;
  width: 100%;
  background-color: #FFFFFF;
  z-index: 1000; }
  #tag_bar .tagBox {
    display: flex;
    overflow-x: scroll;
    align-items: center;
    height: 100%; }
    #tag_bar .tagBox .tagReload {
      margin-left: 10px;
      margin-right: -5px; }
      #tag_bar .tagBox .tagReload img {
        height: 28px; }
    #tag_bar .tagBox .loadedTags {
      display: flex; }
      #tag_bar .tagBox .loadedTags .tagCell {
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        padding: 0px 10px;
        margin: 0px 0px 0px 15px;
        width: auto;
        height: 30px;
        background-color: #fff1f0;
        color: #FF0000;
        font-weight: 600;
        font-size: 14px; }
        #tag_bar .tagBox .loadedTags .tagCell img {
          margin-right: 5px; }
    #tag_bar .tagBox .openNearTag-modal {
      padding: 4.5px 10px 0px 10px; }
      #tag_bar .tagBox .openNearTag-modal img {
        height: 10px; }

#modal-menu {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  z-index: 500; }
  #modal-menu .modal_menu_body {
    position: relative;
    overflow-y: scroll;
    top: 41px;
    width: inherit;
    height: inherit;
    background: white; }
    #modal-menu .modal_menu_body .heightadjust {
      position: relative;
      padding-top: 7px; }
    #modal-menu .modal_menu_body .menuline {
      margin: 17px 0 0 25px;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 1px;
      color: #000000; }
      #modal-menu .modal_menu_body .menuline .selectedlanguage .selectedlang {
        display: block;
        margin: 5px 0 0 9px;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 1px;
        color: #979797; }
    #modal-menu .modal_menu_body .selectionwrap {
      display: none; }
      #modal-menu .modal_menu_body .selectionwrap .selection {
        margin: 0px 16px 0px 16px;
        display: flex;
        flex-wrap: wrap; }
    #modal-menu .modal_menu_body .lang {
      display: block;
      margin: 13px 0 0 18px;
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 1px;
      color: #979797; }
  #modal-menu .links {
    margin: 17px 0 17px 21px;
    display: flex;
    align-items: center; }
    #modal-menu .links .icon {
      margin-left: 25px; }
  #modal-menu .keywords {
    margin: 0 27px; }
    #modal-menu .keywords .keyword {
      display: block;
      margin-bottom: 9px;
      padding-bottom: 3px;
      border-bottom: 1px solid #F7F6EB;
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 1px;
      color: #000000; }
  #modal-menu .bannerBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 47px; }
    #modal-menu .bannerBox .link-ios {
      display: block; }
      #modal-menu .bannerBox .link-ios .banner-ios {
        height: 40px; }
    #modal-menu .bannerBox .dummy {
      height: 17px; }
    #modal-menu .bannerBox .link-android {
      display: block; }
      #modal-menu .bannerBox .link-android .banner-android {
        height: 40px; }
  #modal-menu .app_text {
    margin-top: 28px;
    margin-bottom: 84px;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #231F20; }

@media screen and (min-width: 768px) {
  #modal-menu .closebtn {
    margin-left: 1%; } }

@media screen and (min-width: 1280px) {
  #modal-menu .closebtn {
    margin-left: 6%; } }

/* page */
.heatmap-canvas {
  filter: blur(10px); }

img.leaflet-tile-loaded {
  filter: grayscale(50%); }

.leaflet-top.leaflet-left {
  width: 100%;
  display: flex;
  justify-content: center; }

.top {
  height: 100%; }
  .top .whitewrapper {
    display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    padding: 100%;
    background-color: #FFFFFF;
    z-index: 100; }
  .top #Sec01 {
    position: relative;
    margin-top: 38px; }
    .top #Sec01 .inner {
      transition: 1.0s; }
  .top #Sec02 {
    position: relative; }
    .top #Sec02 .inner {
      margin: 0;
      width: auto; }
      .top #Sec02 .inner .filter {
        margin: 0 13px 0px 13px;
        font-weight: bold;
        font-size: 32px;
        line-height: 48px;
        color: #231F20; }
      .top #Sec02 .inner .card-area {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-bottom: 20px; }
      .top #Sec02 .inner .notFound {
        display: none;
        padding-top: 150px;
        width: 100%;
        text-align: center;
        color: #000000;
        font-size: 2.6rem; }
  .top #Sec03 {
    display: none; }

@media screen and (min-width: 1280px) {
  .top #Sec02 {
    margin-left: 60px;
    margin-right: 60px; }
    .top #Sec02 .inner .filter {
      margin: 21px 13px 0px 33px;
      font-weight: bold;
      font-size: 32px;
      line-height: 48px;
      color: #231F20; } }

#topads .whitewrapper {
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  padding: 100%;
  background-color: #FFFFFF;
  z-index: 100; }

.detail {
  height: 100%; }
  .detail .whitewrapper {
    display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    padding: 100%;
    background-color: #FFFFFF;
    z-index: 100; }
  .detail #Sec01 {
    position: relative;
    margin-top: 45px; }
    .detail #Sec01 .inner {
      transition: 1.0s; }
  .detail #Sec02 {
    position: relative; }
    .detail #Sec02 .inner {
      margin: 0;
      width: 100%; }
  .detail #Sec03 {
    display: none; }

@media screen and (max-width: 768px) {
  /* 768pxまでの幅の場合に適応される */
  /* 768pxまでの幅の場合に適応される */ }

.about {
  overflow: hidden;
  /*ムービー追加*/ }
  .about #Sec01 {
    position: relative; }
    .about #Sec01 .inner .catchBox {
      margin-bottom: -5px; }
      .about #Sec01 .inner .catchBox video.video-pc {
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        background-color: #000000;
        background-size: cover; }
      .about #Sec01 .inner .catchBox video.video-sp {
        display: none; }
      .about #Sec01 .inner .catchBox .filterOnVideo {
        position: absolute;
        top: 0;
        left: 0;
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: calc(100% - 5px);
        background-color: #00000040; }
      .about #Sec01 .inner .catchBox .str4 {
        position: absolute;
        bottom: 54px;
        padding: 0 30px;
        color: #FFFFFF;
        font-size: 4.51vh;
        font-weight: 600; }
      .about #Sec01 .inner .catchBox .arrowBox-sp {
        display: none; }
  .about #moviebox {
    text-align: center;
    padding: 40px 0;
    width: 100%; }
    .about #moviebox .movie {
      max-width: 800px;
      width: 90%;
      margin: 0 auto;
      height: 400px; }
  .about #Sec02 {
    position: relative; }
    .about #Sec02 .inner .boxes .row {
      display: flex; }
      .about #Sec02 .inner .boxes .row .leftBox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        min-height: 300px; }
        .about #Sec02 .inner .boxes .row .leftBox.row1 {
          background-color: #FF233E42; }
          .about #Sec02 .inner .boxes .row .leftBox.row1 img.appIcon {
            display: block;
            width: 120px;
            height: 120px; }
        .about #Sec02 .inner .boxes .row .leftBox.row2 {
          background-color: #FF233E; }
          .about #Sec02 .inner .boxes .row .leftBox.row2 .dlBox {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 100%; }
            .about #Sec02 .inner .boxes .row .leftBox.row2 .dlBox .dummy {
              width: 10px; }
            .about #Sec02 .inner .boxes .row .leftBox.row2 .dlBox .str2 {
              color: #FFFFFF;
              font-size: 5.2rem;
              font-weight: 600; }
            .about #Sec02 .inner .boxes .row .leftBox.row2 .dlBox .arrow {
              padding-right: 5px;
              display: block;
              width: auto;
              height: 35px; }
        .about #Sec02 .inner .boxes .row .leftBox.row3 {
          background-color: #FF233E42;
          cursor: pointer; }
          .about #Sec02 .inner .boxes .row .leftBox.row3 .webBox img {
            width: 256px;
            height: auto; }
      .about #Sec02 .inner .boxes .row .rightBox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        min-height: 300px; }
        .about #Sec02 .inner .boxes .row .rightBox.row1 {
          background-color: #FF233E; }
          .about #Sec02 .inner .boxes .row .rightBox.row1 .str1 {
            padding: 0 65px;
            color: #FFFFFF;
            font-size: 3.2rem;
            font-weight: 600;
            line-height: 5.0rem; }
        .about #Sec02 .inner .boxes .row .rightBox.row2 {
          background-color: #FF233E42; }
          .about #Sec02 .inner .boxes .row .rightBox.row2 .bannerBox {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%; }
            .about #Sec02 .inner .boxes .row .rightBox.row2 .bannerBox .banner-ios {
              height: 67px; }
            .about #Sec02 .inner .boxes .row .rightBox.row2 .bannerBox .banner-android {
              height: 67px; }
            .about #Sec02 .inner .boxes .row .rightBox.row2 .bannerBox .dummy {
              width: 30px; }
        .about #Sec02 .inner .boxes .row .rightBox.row3 {
          background-color: #FF233E;
          cursor: pointer; }
          .about #Sec02 .inner .boxes .row .rightBox.row3 .strBox {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%; }
            .about #Sec02 .inner .boxes .row .rightBox.row3 .strBox .arrow {
              padding-left: 5px;
              display: block;
              width: auto;
              height: 35px; }
            .about #Sec02 .inner .boxes .row .rightBox.row3 .strBox .arrow-sp {
              display: none; }
            .about #Sec02 .inner .boxes .row .rightBox.row3 .strBox .dummy {
              width: 8%; }
            .about #Sec02 .inner .boxes .row .rightBox.row3 .strBox .str3 {
              padding-right: 10px;
              width: 75%;
              color: #FFFFFF;
              font-size: 3.6rem;
              font-weight: 600; }

@media screen and (min-width: 1660px) {
  /* 1500px以上 */
  .about #Sec02 .inner .boxes .row .rightBox.row3 .str3 {
    padding-right: 5% !important;
    width: 70% !important;
    text-align: center; }
  /* end 1500px以上 */ }

@media screen and (max-width: 1023px) {
  /* 1023px以下 */
  .about #Sec01 .inner .catchBox .str4 {
    font-size: 3.1vh;
    line-height: 4vh; }
  .about #Sec02 .inner .boxes .row {
    flex-wrap: wrap; }
    .about #Sec02 .inner .boxes .row.row3 {
      flex-direction: column-reverse; }
    .about #Sec02 .inner .boxes .row .leftBox {
      width: 100%; }
      .about #Sec02 .inner .boxes .row .leftBox.row2 {
        display: none; }
    .about #Sec02 .inner .boxes .row .rightBox {
      width: 100%; }
      .about #Sec02 .inner .boxes .row .rightBox.row3 .strBox {
        flex-direction: column; }
        .about #Sec02 .inner .boxes .row .rightBox.row3 .strBox .arrow {
          display: none; }
        .about #Sec02 .inner .boxes .row .rightBox.row3 .strBox .arrow-sp {
          padding-top: 45px;
          display: block;
          width: 35px;
          height: auto; }
        .about #Sec02 .inner .boxes .row .rightBox.row3 .strBox .dummy {
          display: none; }
        .about #Sec02 .inner .boxes .row .rightBox.row3 .strBox .str3 {
          width: 100%;
          text-align: center; }
  /* end 1023px以下 */ }

@media screen and (max-width: 768px) {
  /* 768px以下 */
  .about #Sec01 .inner .catchBox {
    margin-bottom: 0px; }
    .about #Sec01 .inner .catchBox video.video-pc {
      display: none; }
    .about #Sec01 .inner .catchBox video.video-sp {
      display: block;
      width: auto;
      height: auto;
      min-width: 100%;
      min-height: 100%;
      background-color: #000000;
      background-size: cover; }
    .about #Sec01 .inner .catchBox .filterOnVideo {
      min-height: 100%; }
    .about #Sec01 .inner .catchBox .str4 {
      bottom: 60px;
      padding: 0 15px;
      font-size: 2.6vh;
      font-weight: 600;
      line-height: 3.5vh; }
    .about #Sec01 .inner .catchBox .arrowBox-sp {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 25px;
      left: 0;
      width: 100%; }
      .about #Sec01 .inner .catchBox .arrowBox-sp .arrow-sp {
        width: 35px;
        height: 17px; }
  .about #Sec02 .inner .boxes .row .rightBox.row1 .str1 {
    width: 83%;
    padding: 0;
    font-size: 2.6rem;
    line-height: 3.5rem; }
  .about #Sec02 .inner .boxes .row .rightBox.row2 .bannerBox {
    flex-direction: column;
    justify-content: center; }
    .about #Sec02 .inner .boxes .row .rightBox.row2 .bannerBox .dummy {
      width: 0;
      height: 30px; }
    .about #Sec02 .inner .boxes .row .rightBox.row2 .bannerBox .link-ios {
      text-align: center; }
    .about #Sec02 .inner .boxes .row .rightBox.row2 .bannerBox .link-android {
      text-align: center; }
  .about #Sec02 .inner .boxes .row .rightBox.row3 .strBox .str3 {
    padding: 0;
    width: 83%;
    font-size: 2.6rem;
    line-height: 3.5rem;
    text-align: center; }
  /* end 768px以下 */ }

.policy #Sec01 {
  margin-top: 47px; }
  .policy #Sec01 .inner {
    position: relative; }
    .policy #Sec01 .inner h1.title {
      padding-top: 30px;
      font-size: 1.8rem; }
    .policy #Sec01 .inner .content {
      padding-top: 20px;
      padding-bottom: 20px; }

.privacy #Sec01 {
  margin: 0 15px 10px 15px; }
  .privacy #Sec01 .inner {
    position: relative; }
    .privacy #Sec01 .inner h1.page_title {
      padding-top: 30px;
      font-size: 1.8rem;
      font-weight: 600; }
    .privacy #Sec01 .inner h2.heading {
      padding-top: 30px;
      font-size: 1.5rem;
      font-weight: 600; }
    .privacy #Sec01 .inner .str {
      padding-top: 10px; }
    .privacy #Sec01 .inner .caution {
      color: #FF233E;
      font-size: 0.7rem;
      margin-left: 2em;
      padding: 0 0 0 2em;
      font-weight: 400; }
      .privacy #Sec01 .inner .caution:before {
        display: marker;
        content: "※";
        margin-left: -2em; }
    .privacy #Sec01 .inner ol.list_str {
      padding: 0 0 0 2em;
      margin: 0;
      margin-top: 10px; }
    .privacy #Sec01 .inner ol.list_str li {
      list-style-type: none;
      list-style-position: inside;
      counter-increment: cnt;
      font-size: 1.2rem;
      padding-top: 5px; }
    .privacy #Sec01 .inner ol.list_str li:before {
      display: marker;
      content: "(" counter(cnt) ") ";
      margin-left: -2em; }
    .privacy #Sec01 .inner ol.list_str2 {
      padding: 0 0 0 2em;
      margin: 0;
      margin-top: 10px;
      --sec-num: "1"; }
      .privacy #Sec01 .inner ol.list_str2.sec3 {
        --sec-num: "3"; }
      .privacy #Sec01 .inner ol.list_str2.sec4 {
        --sec-num: "4"; }
      .privacy #Sec01 .inner ol.list_str2.sec7 {
        --sec-num: "7"; }
    .privacy #Sec01 .inner ol.list_str2 li {
      list-style-type: none;
      list-style-position: inside;
      counter-increment: cnt;
      font-size: 1.2rem; }
    .privacy #Sec01 .inner ol.list_str2 li:before {
      display: marker;
      content: var(--sec-num) "-" counter(cnt) " ";
      margin-left: -2em; }
    .privacy #Sec01 .inner .scroll {
      max-width: 100%;
      overflow: auto; }
    .privacy #Sec01 .inner .scroll::-webkit-scrollbar {
      height: 5px; }
    .privacy #Sec01 .inner .scroll::-webkit-scrollbar-track {
      background: #F1F1F1; }
    .privacy #Sec01 .inner .scroll::-webkit-scrollbar-thumb {
      background: #BCBCBC; }
    .privacy #Sec01 .inner table.tbl {
      margin-top: 5px;
      width: 100%; }
      .privacy #Sec01 .inner table.tbl tr {
        counter-increment: cnt_tbl; }
      .privacy #Sec01 .inner table.tbl td.left {
        min-width: 300px;
        border: 1px solid #D8D8D8;
        border-right: 0px solid #D8D8D8;
        padding: 5px 5px 5px 30px; }
        .privacy #Sec01 .inner table.tbl td.left:before {
          display: marker;
          content: "(" counter(cnt_tbl) ") ";
          margin-left: -20px; }
      .privacy #Sec01 .inner table.tbl td.right {
        min-width: 400px;
        border: 1px solid #D8D8D8;
        padding: 5px 10px; }

.terms #Sec01 {
  margin: 0 15px 10px 15px; }
  .terms #Sec01 .inner {
    position: relative; }
    .terms #Sec01 .inner h1.page_title {
      padding-top: 30px;
      font-size: 1.8rem;
      font-weight: 600; }
    .terms #Sec01 .inner h2.heading {
      padding-top: 30px;
      font-size: 1.5rem;
      font-weight: 600; }
    .terms #Sec01 .inner .str {
      padding-top: 10px;
      font-size: 1.2rem; }
    .terms #Sec01 .inner ol.list_str {
      padding: 0 0 0 2.0em;
      margin-top: 10px; }
      .terms #Sec01 .inner ol.list_str li {
        font-size: 1.2rem;
        padding-top: 5px; }
      .terms #Sec01 .inner ol.list_str.type1 {
        padding: 0 0 0 1.5em; }
        .terms #Sec01 .inner ol.list_str.type1 li {
          list-style-type: none;
          list-style-position: inside;
          counter-increment: cnt1; }
        .terms #Sec01 .inner ol.list_str.type1 li:before {
          display: marker;
          content: counter(cnt1) ". ";
          margin-left: -1.5em; }
      .terms #Sec01 .inner ol.list_str.type2 {
        padding: 0 0 0 2.0em; }
        .terms #Sec01 .inner ol.list_str.type2 li {
          list-style-type: none;
          list-style-position: inside;
          counter-increment: cnt2; }
        .terms #Sec01 .inner ol.list_str.type2 li:before {
          display: marker;
          content: "(" counter(cnt2) ") ";
          margin-left: -2.0rem; }
      .terms #Sec01 .inner ol.list_str.type3 {
        padding: 0 0 0 1.5em; }
        .terms #Sec01 .inner ol.list_str.type3 li {
          list-style-type: none;
          list-style-position: inside;
          counter-increment: cnt3; }
        .terms #Sec01 .inner ol.list_str.type3 li:before {
          display: marker;
          content: "・ ";
          margin-left: -1.5em; }

.rules #Sec01 {
  margin: 0 15px 10px 15px; }
  .rules #Sec01 .inner {
    position: relative; }
    .rules #Sec01 .inner h1.page_title {
      padding-top: 30px;
      font-size: 1.8rem;
      font-weight: 600; }
    .rules #Sec01 .inner h2.heading {
      padding-top: 30px;
      font-size: 1.5rem;
      font-weight: 600; }
    .rules #Sec01 .inner .str {
      padding-top: 10px;
      font-size: 1.2rem; }
    .rules #Sec01 .inner ol.list_str {
      padding: 0 0 0 2.0em;
      margin-top: 10px; }
      .rules #Sec01 .inner ol.list_str li {
        font-size: 1.2rem;
        padding-top: 5px; }
      .rules #Sec01 .inner ol.list_str.type1 {
        padding: 0 0 0 1.5em; }
        .rules #Sec01 .inner ol.list_str.type1 li {
          list-style-type: none;
          list-style-position: inside;
          counter-increment: cnt1; }
        .rules #Sec01 .inner ol.list_str.type1 li:before {
          display: marker;
          content: counter(cnt1) ". ";
          margin-left: -1.5em; }
      .rules #Sec01 .inner ol.list_str.type2 {
        padding: 0 0 0 2.0em; }
        .rules #Sec01 .inner ol.list_str.type2 li {
          list-style-type: none;
          list-style-position: inside;
          counter-increment: cnt2; }
        .rules #Sec01 .inner ol.list_str.type2 li:before {
          display: marker;
          content: "(" counter(cnt2) ") ";
          margin-left: -2.0rem; }
      .rules #Sec01 .inner ol.list_str.type3 {
        padding: 0 0 0 1.5em;
        margin-top: 0; }
        .rules #Sec01 .inner ol.list_str.type3 li {
          list-style-type: none;
          list-style-position: inside;
          counter-increment: cnt3; }
        .rules #Sec01 .inner ol.list_str.type3 li:before {
          display: marker;
          content: "・ ";
          margin-left: -1.5em; }

.card #Sec01 .inner .card {
  background: #FFFFFF;
  max-width: 375px;
  min-width: 320px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  font-family: HiraginoSans-W5;
  font-size: 1000px;
  font-color: #FF233E;
  letter-spacing: -1.16px;
  font-family: SFProText-Semibold;
  font-size: 42px;
  color: #231F20;
  letter-spacing: -3.97px;
  border-radius: 8px; }

.admin #Sec01 .inner {
  width: 100%;
  margin: 0;
  padding: 0; }
  .admin #Sec01 .inner .map-box {
    width: 100%;
    height: 80vh; }
    .admin #Sec01 .inner .map-box #map {
      width: 100%;
      height: 100%; }
  .admin #Sec01 .inner .formArea {
    width: 100%;
    height: 20vh;
    padding-left: 20px; }
    .admin #Sec01 .inner .formArea .formBox {
      display: flex;
      height: 100%; }
      .admin #Sec01 .inner .formArea .formBox .left {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-around; }
      .admin #Sec01 .inner .formArea .formBox .right {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center; }
        .admin #Sec01 .inner .formArea .formBox .right #btn_edit {
          height: 100%;
          display: none;
          flex-direction: column;
          justify-content: space-around; }
        .admin #Sec01 .inner .formArea .formBox .right #btn_new {
          height: 100%;
          flex-direction: column;
          justify-content: space-around; }
        .admin #Sec01 .inner .formArea .formBox .right .btnBox {
          display: flex;
          justify-content: center; }
          .admin #Sec01 .inner .formArea .formBox .right .btnBox .btn {
            display: block;
            border: 1px #FF233E;
            background: #FF233E;
            color: #FFFFFF; }
            .admin #Sec01 .inner .formArea .formBox .right .btnBox .btn.sitemap {
              font-size: 0.9rem;
              background: #000000;
              color: #FFFFFF; }
      .admin #Sec01 .inner .formArea .formBox input, .admin #Sec01 .inner .formArea .formBox textarea {
        width: 100%;
        -webkit-appearance: none;
        border: none;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2) inset;
        border-radius: 0;
        box-sizing: border-box;
        padding: 5px;
        font-size: 1.5rem; }

.admin .loaderBox {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.5); }
  .admin .loaderBox #seo-loader {
    margin: 40vh auto; }

#modal-contentLink {
  display: none; }

.modal-contentLink-custom .modaal-container {
  max-width: 400px;
  width: 80%;
  background-color: #00000000;
  box-shadow: none; }
  .modal-contentLink-custom .modaal-container .modaal-content-container {
    padding: 10px 5px !important; }
    .modal-contentLink-custom .modaal-container .modaal-content-container .modal_contentLink_body .upper {
      background-color: #FFFFFF;
      border-radius: 15px; }
      .modal-contentLink-custom .modaal-container .modaal-content-container .modal_contentLink_body .upper .titleBox {
        padding: 10px 5px;
        display: flex;
        justify-content: center; }
        .modal-contentLink-custom .modaal-container .modaal-content-container .modal_contentLink_body .upper .titleBox .title {
          font-size: 13px;
          font-weight: 600; }
    .modal-contentLink-custom .modaal-container .modaal-content-container .modal_contentLink_body .lower {
      margin-top: 20px;
      background-color: #FFFFFF;
      border-radius: 15px; }
    .modal-contentLink-custom .modaal-container .modaal-content-container .modal_contentLink_body .btnBox {
      padding: 10px 5px;
      display: flex;
      justify-content: center; }
      .modal-contentLink-custom .modaal-container .modaal-content-container .modal_contentLink_body .btnBox.bt {
        border-top: solid 2px #F0F0F0; }
      .modal-contentLink-custom .modaal-container .modaal-content-container .modal_contentLink_body .btnBox .btn {
        display: block;
        border-radius: 0;
        width: 100%;
        font-size: 17px;
        font-weight: 500; }
        .modal-contentLink-custom .modaal-container .modaal-content-container .modal_contentLink_body .btnBox .btn.btn-translate, .modal-contentLink-custom .modaal-container .modaal-content-container .modal_contentLink_body .btnBox .btn.btn-original {
          color: #FF233E; }
        .modal-contentLink-custom .modaal-container .modaal-content-container .modal_contentLink_body .btnBox .btn.btn-cancel {
          color: #979797; }
