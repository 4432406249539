/*--------------------------------------------------------------------------
   html
---------------------------------------------------------------------------*/
html{
  overflow-y: scroll;
  font-size: 62.5%;
}

/*--------------------------------------------------------------------------
   body
---------------------------------------------------------------------------*/
// @import url('https://fonts.googleapis.com/css?family=Sawarabi+Mincho');
// @import url('https://fonts.googleapis.com/css?family=Sansita');

body{
  font-family: "ヒラギノ角ゴ Pro W5","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
  color: #3E3A39;
  font-size: 1.3rem;
  letter-spacing: .05em;
  line-height: 1.5;
  color: #3E3A39;

  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;

  background: #ffffff;
}

@keyframes fadeIn {
    0% {opacity: 0}
    100% {opacity: 1}
}

@-webkit-keyframes fadeIn {
    0% {opacity: 0}
    100% {opacity: 1}
}

h1 {
  font-size: 2.8rem;
}

h2 {
  font-size: 2.4rem;
}

h3 {
  font-size: 1.8rem;
}

// p {
//   font-size: 1.5rem;
// }

.adobe-font {
  font-family: reenie-beanie, sans-serif;
  font-weight: 500;
  font-style: normal;
}

/*--------------------------------------------------------------------------
   base link
---------------------------------------------------------------------------*/
a{
  outline: none;
  -webkit-tap-highlight-color: rgba(255,255,255, 0.7);
  color: #3E3A39;
  transition: 0.5s;
}
a:link,
a:visited{
  text-decoration: none;
}
a:hover{
  text-decoration: none;
  outline: none;
  opacity: 0.7;
  transition: 0.5s;
}
a:active{
  outline: none;
}

/*--------------------------------------------------------------------------
   Other
---------------------------------------------------------------------------*/

input:focus, input[type]:focus, .uneditable-input:focus {
  outline: 0;
  box-shadow: none;
  border: 0px solid #FFFFFF;
  background-color: #ffffff;
}


/* clearfix
-----------------------------------------------------------------*/
.cf:before,
.cf:after{
  content: "";
  display: table;
}
.cf:after{
  clear: both;
}
.cf{
  zoom: 1;
}
.sp{
  display: none!important;
}


// @media screen and (max-width: 768px) {
// /* 768pxまでの幅の場合に適応される */
//
// h1 {
//   font-size: 2.2rem;
// }
//
// h2 {
//   font-size: 1.8rem;
// }
//
// h3 {
//   font-size: 1.4rem;
// }
//
// p {
//   font-size: 1.2rem;
// }
//
// .sp{
//   display: inline-block!important;
// }
// .pc{
//   display: none!important;
// }
//
// } //media
