
#modal-menu {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  z-index: 500; //whitewrapperより大きくheaderより小さい

  .modal_menu_body {
    position: relative;
    overflow-y: scroll;
    top: 41px;
    width: inherit;
    height: inherit;
    background: rgba(255, 255, 255, 1);

    .heightadjust {position: relative; padding-top: 7px;}

    .menuline {
      margin:17px 0 0 25px;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 1px;
      color: #000000;

      .selectedlanguage {
        .selectedlang {
          display: block;
          margin: 5px 0 0 9px;
          font-weight: bold;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 1px;
          color: #979797;
        } //.selectedlang
      } //.selectedlanguage

    } //.menuline

    .selectionwrap{
      display: none;
      .selection {
        margin:0px 16px 0px 16px;
        display: flex;
        flex-wrap: wrap;

      } //.selection
    } //.selectionwrap

    .lang {
      display: block;
      margin: 13px 0 0 18px;
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 1px;
      color: #979797;
    } //.lang

  } //.modal_menu_body

  .links {
    margin: 17px 0 17px 21px;
    display: flex;
    align-items: center;
    .icon {
    margin-left: 25px;
    }
  } //.links

  .keywords {

    margin: 0 27px;
    .keyword {

      display: block;
      margin-bottom: 9px;
      padding-bottom: 3px;
      border-bottom:1px solid #F7F6EB;

      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 1px;
      color: #000000;

    } //.keyword
  } //.keywords

  .bannerBox{
    display: flex;
    flex-direction:column;
    align-items: center;
    margin-top: 47px;
    .link-ios{
      display: block;
      .banner-ios{
        height: 40px;
      }
    }

    .dummy{
      height: 17px;
    }

    .link-android{
      display: block;
      .banner-android{
      height: 40px;
      }
    }
  }

  .app_text{
    margin-top: 28px;
    margin-bottom: 84px;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #231F20;
  }

}

@media screen and (min-width: 768px){

  #modal-menu {

    .closebtn{
      margin-left: 1%;

    } //.closebtn

  }

}

@media screen and (min-width: 1280px){

  #modal-menu {

    .closebtn{
      margin-left: 6%;

    } //.closebtn

  }

}
