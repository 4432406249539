
#introOverlay {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}

#modal-intro {
  display: none;
  width: 300px;
  height: 470px;
  position: fixed;
  padding: 20px;
  background-color: #FFF;
  z-index: 10001;
  overflow: hidden;

  img{
    margin-top: 10px;
    height: 75px;
  }

  .head{
    font-size: 24px;
    text-align: center;
    color: #8E8E93;
  }

  .text{
    margin-top: 10px;
    font-size: 18px;
    line-height: 25px;
  }

  .btnBox{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;

    .btnClose {
      width: 190px;
      line-height: 35px;
      text-align: center;
      font-size: 14px;
      margin: 0px 20px;
      width: 100%;
      color: $pink;
      border-radius: 0px;
      border: solid 1px $pink;
      background: #FFFFFF;

    } //.btnClose
  } //.btnBox

}
