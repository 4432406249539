
.admin {

  #Sec01 {

    .inner {
      width: 100%;
      margin: 0;
      padding: 0;

      .map-box {
        width: 100%;
        height: 80vh;

        #map {
          width: 100%;
          height: 100%;

        } //#map

      } //.map-box

      .formArea {
        width: 100%;
        height: 20vh;
        padding-left: 20px;

        .formBox {
          display: flex;
          height: 100%;

          .left {
            width: 80%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
          }

          .right {
            width: 20%;
            display: flex;
            justify-content: center;
            align-items: center;

            #btn_edit {
              height: 100%;
              display: none;
              flex-direction: column;
              justify-content: space-around;
            }
            #btn_new {
              height: 100%;
              flex-direction: column;
              justify-content: space-around;
            }

            .btnBox {
              display: flex;
              justify-content: center;

              .btn {
                display: block;
                border: 1px $pink;
                background: $pink;
                color: #FFFFFF;

                &.sitemap {
                  font-size: 0.9rem;
                  background: #000000;
                  color: #FFFFFF;
                }
              }
            } //.btnBox

          } //.right


          input, textarea {
            width: 100%;
            -webkit-appearance: none;
            border: none;
            box-shadow: 0 1px 4px 0 rgba(0,0,0,0.20) inset;
            border-radius: 0;
            box-sizing: border-box;
            padding: 5px;
            font-size: 1.5rem;
          }


        } //.formBox

      } //.formArea

    } //.inner

  } //#Sec01

  .loaderBox {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    background: rgba(255, 255, 255, 0.5);

    #seo-loader {
      margin: 40vh auto;
    }
  }

} //.admin
