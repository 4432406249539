/*--------------------------------------------------------------------------
   VARIABLE
---------------------------------------------------------------------------*/
$blue: #1C2E71;
$orange: #FE642E;

$pink: #FF233E;
$pink-light: #FF233E42;
$gray-head: #8E8E93;
$gray: #D8D8D8;

/*--------------------------------------------------------------------------
   MIXIN
---------------------------------------------------------------------------*/
@mixin btn_templete($color: $blue) {
  display: inline-block;
  color: #FFFFFF;
  padding: 20px 10px 20px 10px;
  width: 100%;
  text-align: center;
  font-size: 1.6rem;
  box-sizing: border-box;
  background-color: $color;
  border: solid 1px $color;
  border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
}

.modaal-overlay {
  z-index: 10001 !important;
}

.modaal-wrapper {
  z-index: 10002 !important;
}

/*--------------------------------------------------------------------------
   Header
---------------------------------------------------------------------------*/
#Header{

  .header-flex{
  position:sticky;
  margin-bottom: -38px;
  width: 100%;
  height: 38px;
  z-index: 10000;
  background-color: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);

    .header-wrapper {
      height: 100%;
      display:flex;
      justify-content: space-between;
      align-items:center;

      .menu {
        width:inherit;
        height: 100%;
        align-items: center;

        .open_btn{
          display: block;
          display: flex;
          position: absolute;
          top:0;
          left:0;
          width: 40px;
          height: 100%;
          background: rgba(255, 255, 255, 1);
          align-items: center;
          justify-content: center;
          transition: 0;
          z-index: 10001;

          img{
          margin:10px 18px;
          }
        } //.open_btn

        .close_btn {
          display: block;
          display:flex;
          position: absolute;
          top:0;
          left:0;
          width: 40px;
          height: 100%;
          background: rgba(255, 255, 255, 1);
          align-items: center;
          justify-content: center;
          transition: 0;

          img{
            height: 12px;
            width: 12px;
          }
        } //.close_btn

      } //.menu


      .leftBox{
        display: flex;
        align-items: center;
        margin-left: 10%;

        .logo {

          .header-logo{
            margin-top: 2px;
          }
          .button_min{
            height: 10px;
            width: 10px;
          }
        } //.logo

      }

      // .logo {
      //   margin-left: -5%;
      //
      //   .header-logo{
      //     margin-top: 2px;
      //   }
      //   .button_min{
      //     height: 10px;
      //     width: 10px;
      //   }
      // } //.logo

      .keywords{
        display: none;
      }

      .widewrap{
        display: flex;

        .nav{
          display: none;
        }
        .twitter {
          display: none;
        }

        .mirrorball {
          height: 100%;
          display: flex;
          align-items: center;
          padding:0px 16px;

          img{
          height:20px;
          width:20px;
          }

        } //.mirrorball

      } //.widewrap

    } //.header-wrapper
  }

  .header-fixed{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 38px;
    z-index: 10000;
    background-color: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);

    .header-wrapper {
      height: 100%;
      display:flex;
      justify-content: space-between;
      align-items:center;

      .menu {
        width:inherit;
        height: 100%;
        align-items: center;

        .open_btn{
          display: block;
          display: flex;
          position: absolute;
          top:0;
          left:0;
          width: 40px;
          height: 100%;
          background: rgba(255, 255, 255, 1);
          align-items: center;
          justify-content: center;
          transition: 0;
          z-index: 10001;

          img{
          margin:10px 18px;
          }
        } //.open_btn

        .close_btn {
          display: block;
          display:flex;
          position: absolute;
          top:0;
          left:0;
          width: 40px;
          height: 100%;
          background: rgba(255, 255, 255, 1);
          align-items: center;
          justify-content: center;
          transition: 0;

          img{
            height: 12px;
            width: 12px;
          }
        } //.close_btn

      } //.menu


      .leftBox{
        display: flex;
        align-items: center;
        margin-left: 10%;

        .logo {

          .header-logo{
            margin-top: 2px;
          }
          .button_min{
            height: 10px;
            width: 10px;
          }
        } //.logo

      }

      .keywords{
        display: none;
      }

      .widewrap{
        display: flex;

        .nav{
          display: none;
        }
        .twitter {
          display: none;
        }

        .mirrorball {
          height: 100%;
          display: flex;
          align-items: center;
          padding:0px 16px;

          img{
          height:20px;
          width:20px;
          }

        } //.mirrorball
      } //.widewrap

    } //.header-wrapper

  } //.header-fixed

} //#Header

@media screen and (max-width: 768px){

#Header .header-fixed .header-wrapper .leftBox, #Header .header-flex .header-wrapper .leftBox {
  width: 53vw;

  .logo {
    width: 200px;
  }
}

}


@media screen and (min-width: 768px){
  #Header{
    .header-fixed,.header-flex{
      .header-wrapper{
        margin: 0% 1%;
        .menu{
          .open_btn{left:1%;}
          .close_btn{left:1%;}
        }
        .keywords{
          margin-left: -9%;
          display: inherit;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 18px;
          color: #231F20;

          .keyword{
            display: inherit;
            margin-left: 75px;
            padding: 0 10px;
            &:hover {
              color: #231F20;
            }
          }
        }


        .widewrap{
          display: flex;
          justify-content: center;
          align-items: center;

        //---------------------
        //アコーディオン
        //---------------------

        .nav{
          display: inherit;
          cursor: pointer;

        }

        .nav:hover{
          img{
          margin-left: 10px;
          margin-top: 2px;
          transform: rotate(-180deg);
          transition: .3s;
          }
        }

        .nav ul {
            font-size:0;
        }

        .nav ul li { //親要素
          display:inline-block;
          width:180px;

          position: relative;
          background: rgba(255, 255, 255, 0.95);
        }

        .nav ul li a { //親aタグ
          text-align: center;
          display: block;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;

          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 18px;
          color: #231F20;

          img{
            margin-left: 10px;
            margin-top: 2px;
            transition: .3s;
          }

        }
        .nav ul li a:hover { //親aタグホバー
          text-align: center;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 18px;
          color: #231F20;

        }
        .nav li ul {
          position: absolute;
          z-index: 9999;
          width: 100%;
        }
        .nav li ul li {
          display: block;
          width: 100%;

        }

        .nav li ul li a {
          height: 32px;
          padding: 8px 0px 5px 0px;
          margin: 0px 18px;
          border-bottom: 1px solid #F7F6EB;

        }

        // .nav > ul > li > ul > li:nth-child(13) > a{ //余白部分
        //   height: 20px;
        //   border-bottom: none;
        // }

        .nav > ul > li > ul > li.dummy > a{ //余白部分
          height: 19px;
          border-bottom: none;
        }

        .nav li ul li{
          overflow: hidden;
          height: 0;
          transition: .3s;
        }
        .nav li:hover ul li{
          overflow: visible;
          height: 32px;
        }

        // .nav li:hover ul li:nth-child(13){ //余白部分
        //   overflow: visible;
        //   height: 20px;
        // }

        .nav li:hover ul li.dummy{ //余白部分
          overflow: visible;
          height: 19px;
        }

        //---------------------
        //アコーディオンここまで
        //---------------------

        .twitter {
          display: inherit;
          img{
            height: 18px;
            width: 18px;
          }
        }

      }


    } //.header-wrapper
  } //.header-fixed
} //#Header
} //768

@media screen and (min-width: 1280px){
  #Header{
    .header-fixed,.header-flex{
      .header-wrapper{
        margin: 0% 5%;
        .menu{
          .open_btn{left:5%;}
          .close_btn{left:5%;}
        }
        // .logo{
        //   margin-left: -23%;
        // }
        //
        // .keywords{
        //   margin-left: -33%;
        // }

    } //.header-wrapper
  } //.header-fixed
} //#Header
} //1280

/*--------------------------------------------------------------------------
   MOTION関連
---------------------------------------------------------------------------*/

.motion {
  margin-top: -160px;
}

.appimg {
  margin-top: -160px;
}

/*--------------------------------------------------------------------------
   RANK関連
---------------------------------------------------------------------------*/
#loadedRank {

  display: block;
  padding:14px 3px 22px 3px;

  .rankLine {
    margin: 5px 23px 0px 23px;
    min-height: 75px;
    border-bottom: 1px solid #F7F6EB;

    .line-head{
    display: flex;
    align-items: center;
      img{
        width: 15px;
        height: 12px;
        margin-right: 7px;
      }

      .linenum{
        margin-right: 14px;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #CDCDCD;
      }

      .postdate{
        font-weight: bold;
        font-size: 10px;
        line-height: 15px;
        color: #8E8E93;
        white-space: nowrap;
      }

    } //.line-head

    .rank-inner{
      display: flex;
      align-items: center;

      .rank-img{
        display: flex;
        align-items: center;
        justify-content: center;
        height:36px;
        width:46px;
        background-color: #222222;
        overflow: hidden;

        img{
          object-fit: cover;
          height:36px;
          width:46px;
        }

      } //.rank-img

      .rank-title{
        word-wrap: break-word;
        display: -webkit-box;
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
        -webkit-line-clamp: 2;
        overflow: hidden;

        margin-left: 5px;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        color: #231F20;
        width: 100%;

      } //.rank-title

    } //.rank-inner

  } //.rank-line
}

#rankforwide{
  display: none;

}

@media screen and (min-width: 1280px){
  #loadedRank {
    display: none;
  }

  #rankforwide{
    display: block;
    width: 367px;
    margin-top: 45px;
    padding:14px 3px 22px 3px;

      .rankLine {
        margin: 5px 23px 0px 23px;
        min-height: 75px;
        border-bottom: 1px solid #F7F6EB;

        .line-head{
        display: flex;
        align-items: center;
          img{
            width: 15px;
            height: 12px;
            margin-right: 7px;
          }

          .linenum{
            margin-right: 14px;
            font-weight: bold;
            font-size: 20px;
            line-height: 30px;
            color: #CDCDCD;
          }

          .postdate{
            font-weight: bold;
            font-size: 10px;
            line-height: 15px;
            color: #8E8E93;
            white-space: nowrap;
          }

        } //.line-head

        .rank-inner{
          display: flex;
          align-items: center;

          .rank-img{
            display: flex;
            align-items: center;
            justify-content: center;
            height:36px;
            width:46px;
            background-color: #222222;
            overflow: hidden;

            img{
              object-fit: cover;
              height:36px;
              width:46px;
            }

          } //.rank-img

          .rank-title{
            word-wrap: break-word;
            display: -webkit-box;
            /* autoprefixer: off */
            -webkit-box-orient: vertical;
            /* autoprefixer: on */
            -webkit-line-clamp: 2;
            overflow: hidden;

            margin-left: 5px;
            font-weight: bold;
            font-size: 14px;
            line-height: 21px;
            color: #231F20;
            width: 100%;

          } //.rank-title

        } //.rank-inner

      } //.rank-line
  }
} //1280


/*--------------------------------------------------------------------------
   DETAIL関連
---------------------------------------------------------------------------*/
@media screen and (max-width: 648px){
.detail {
  .link-noogp {
    width: 90vw;
  }
}
}

.detail {


  .link-noogp {
    margin: 30px 0;
    padding: 0 30px 5px 30px;
    display: block;
    background-color: $pink;
    cursor: pointer;

    .detailBox {
      display: flex;
      justify-content: center;

      .link-detail-icon {
        margin-right: 15px;
      }

      .link-detail-str {
        text-align: center;
        font-size: 17px;
        font-weight: 600;
        color: #FFFFFF;
      }
    }

    .link-url {
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 9px;
      font-weight: 300;
      color: #FFFFFF;
    }
  }


  .content{
    &.ads{
      justify-content: center;
      &.center{
        border-top: 1px solid #D8D8D8;
        margin-top: 30px;
      }
    }
  }

  #RankAdlinkArea{
    display: none;
  }

  .widewrap{
    display: flex;
  }

  #loadedArea {

    .content{
      margin: 0px 23px 0px 23px;
      border-bottom: 1px solid #F7F6EB;
      padding-bottom: 10px;

      .contenthead {
        display: flex;
        margin: 10px 3px 0px 3px;
        align-items: center;
        width: 100%;

        .category{
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 15px;
          color: #231F20;
          margin-right: 9px;
          white-space: nowrap;

        }

        .postdate{
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 15px;
          color: #8E8E93;
          white-space: nowrap;

        }

        .reactioncountry{
        display: flex;
        flex-wrap: wrap;
        margin: 0px 0px 0px 14px;
        max-width: 220px;

          .flag{
            width: auto;
            height: 12px;
            margin: 0px 9px 3px 0px;

            img{
              width: auto;
              height: 12px;
              border: 0.5px solid #979797;
              box-sizing: border-box;
            }
          }

        }//.reactioncountry

        .share{
          margin-left: auto;
          padding-bottom: 3px;
            img{
              width: 20px;
            }

        }

      } //.contenthead

      .contentinner{

        &.ads{
          justify-content: center;
        }
        border-bottom: none;
        margin: 8px 4px 0px 4px;

        .cnt-img{
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          height:256px;
          min-width: 320px;
          max-width: 420px;
          background-color: #222222;
          overflow: hidden;
          margin: auto;
          img{
            object-fit: cover;
            width: 100%;
          }
          .link-url {
            position: absolute;
            width: 95%;
            bottom: 5px;
            left: 10px;
            color: #FFFFFF;
            font-size: 9px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .contenttitle{
          font-style: normal;
          font-weight: bold;
          font-size: 17px;
          line-height: 25px;
          color: #231F20;
          margin-top: 3px;

        } //.contenttitle

      }

      .contentfooter {
        margin: 9px 4px 0px 4px;

        .translateArea {
          p.originalComment {
            display: none;
            font-size: 10px;

            &.show {
              display: block;
            }
          }
          .btnShowOriginal {
            color: $pink;
            font-size: 10px;
            font-weight: 600;
          }
        }

      }


    }

    .comment {
      margin: 7px 27px 0px 27px;
      border-bottom: 1px solid #F7F6EB;
      padding-bottom: 11px;

      .commenthead {
        margin: 10px 3px 0px 3px;
        display: flex;

        .commenthead-left {
          display: flex;
          justify-content: center;
          align-items: center;

          .flag{
            width: 20px;
            height: 12px;
            margin: 0px 9px 3px 0px;

            img{
              width: auto;
              height: 12px;
              border: 0.5px solid #979797;
              box-sizing: border-box;
            }
          }

        }
        .commenthead-right {
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 15px;
          color: #8E8E93;
        }
      }

      .commentinner {
        margin-top: 10px;

        .cmt-img{
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
          margin-left: auto;
          margin-right: auto;
          height:256px;
          min-width: 320px;
          max-width: 420px;
          background-color: #222222;
          overflow: hidden;
          img{
            object-fit: cover;
            width: 100%;
          }
          .link-url {
            position: absolute;
            width: 95%;
            bottom: 5px;
            left: 10px;
            color: #FFFFFF;
            font-size: 9px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

        }
      }

        .commentComment {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 25px;
          color: #231F20;
        }
      }

      .commentfooter {
        margin-top: 10px;

        .translateArea {
          p.originalComment {
            display: none;
            font-size: 10px;

            &.show {
              display: block;
            }
          }
          .btnShowOriginal {
            color: $pink;
            font-size: 10px;
            font-weight: 600;
          }
        }
      }

    } //#loadedArea

    .bannerBox_U{
      display: block;
      display: flex;
      flex-direction:column;
      align-items: center;
      margin-top: 47px;
      .link-ios{
        display: block;
        .banner-ios{
          height: 40px;
        }
      }

      .dummy{
        height: 17px;
      }

      .link-android{
        display: block;
        .banner-android{
        height: 40px;
        }
      }
    }

    .app_text_U{
      display: block;
      margin-top: 28px;
      margin-left: 28px;
      margin-right: 28px;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 22px;
      text-align: center;
      color: #231F20;
    }

} //.detail

@media screen and (min-width: 768px){
  .detail {

    .widewrap{
    justify-content: center;
    }

    #loadedArea {

      .content{
        .contenthead {

          .category{
            font-style: normal;
            font-weight: bold;
            font-size: 17px;
            line-height: 25px;
            color: #231F20;
            margin-right: 9px;
            white-space: nowrap;

          }

          .postdate{
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 21px;
            color: #8E8E93;
            white-space: nowrap;

          }

          .reactioncountry{
            flex-wrap: nowrap;
            width: auto;
          }//.reactioncountry

          .share{
            margin-left: auto;
            margin-right: 10px;
            padding-bottom: 3px;
              img{
                width: 25px;
              }

          }

        } //.contenthead

        .contentinner{

          &.ads{
            justify-content: center;
          }

          .contenttitle{
            font-style: normal;
            font-weight: bold;
            font-size: 19px;
            line-height: 36px;
            color: #231F20;
            margin-top: 20px;
            margin-bottom: 20px;

          } //.contenttitle
        }

        .contentfooter {
          .translateArea {
            p.originalComment {
              display: none;
              font-size: 12px;

              &.show {
                display: block;
              }
            }
            .btnShowOriginal {
              color: $pink;
              font-size: 12px;
              font-weight: 600;
            }
          }

        }

      }

      .comment{

        .commenthead {

          .commenthead-left {

            .flag{
              width: 30px;
              height: 16px;
              margin: 0px 12px 3px 0px;

              img{
                width: auto;
                height: 16px;
                border: 0.5px solid #979797;
                box-sizing: border-box;
              }
            }

          }
          .commenthead-right {
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 18px;
            color: #8E8E93;
          }
        }

        .commentinner{

          .commentComment{
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 30px;
            color: #231F20;

          }

        }

        .commentfooter {

          .translateArea {
            p.originalComment {
              display: none;
              font-size: 12px;

              &.show {
                display: block;
              }
            }
            .btnShowOriginal {
              color: $pink;
              font-size: 12px;
              font-weight: 600;
            }
          }
        }
      }

    }//#loadedarea

  } //.detail

}//768

@media screen and (min-width: 1280px){
  .detail {

      margin:0px 20px 0px 40px;

      .widewrap{
      margin:0px 10px 0px 10px;
      justify-content: center;
      }

      .bannerBox_U,.app_text_U{
        display: none;
      }

    #RankAdlinkArea {
      display: block;

      *{
        display: block;
      }

        margin-left: 23px;
        display: block;
        width: 367px;

        #rankforwide{
          margin-top: 0px;
          padding:3px 3px 22px 3px;
        }

        .sidebar{
          position: sticky;
          top:0;

          .content {
            margin: 0px 0px 0px 0px;
            padding: 0px 37px 13px 37px;
          }

          .bannerBox{
            display: flex;
            flex-direction:column;
            align-items: center;
            margin-top: 47px;
            .link-ios{
              display: block;
              .banner-ios{
                height: 40px;
              }
            }

            .dummy{
              height: 17px;
            }

            .link-android{
              display: block;
              .banner-android{
              height: 40px;
              }
            }
          }

          .app_text{
            margin-top: 28px;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 22px;
            text-align: center;
            color: #231F20;
          }

          .sidelink{
            display: flex;
            flex-direction: column;
            margin:40px 0px 0px 23px;

            a{
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 30px;
            color: #FF233E;
            }
          }
        }
    }

  } //.detail

}//1280

/*--------------------------------------------------------------------------
   CONTENTS関連
---------------------------------------------------------------------------*/
  #adlinkArea{
    display: none;
  }

  .cntrankwrap{
    display: flex;

    .topcnt{
      .content{
        border-bottom: 1px solid #D8D8D8;
      }
    }
  }

  .content {
    margin: 0px 13px 0px 13px;
    padding-bottom: 13px;
    background: #FFFFFF;
    border-top: 1px solid #D8D8D8;

    &.ads {

      &.side {
        border-bottom: 1px solid #D8D8D8;
      }

      &.center {
        border-top:none;
      }

      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 15px;
      color: #8E8E93;

      .contenthead {
        margin: 18px 0px 8px 1px;
      }

      .contentinner{

        &.ads{
          justify-content: center;
        }

        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: none;
      }

    } //.&ads

    .contentwrap {
      .cnt-img {
        display: none;
      }
      .widespace {
        display: none;
      }

      .contenthead {
        display: flex;
        margin: 10px 3px 0px 3px;
        align-items: center;

        .category{
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 15px;
          color: #231F20;
          margin-right: 9px;
          white-space: nowrap;

        }

        .postdate{
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 15px;
          color: #8E8E93;
          white-space: nowrap;

        }

        .reactioncountry{
        display: flex;
        flex-wrap: wrap;
        margin: 0px 0px 0px 14px;
        max-width: 220px;

          .flag{
            width: 20px;
            height: 12px;
            margin: 0px 9px 3px 0px;

            img{
              width: auto;
              height: 12px;
              border: 0.5px solid #979797;
              box-sizing: border-box;
            }
          }

        }//.reactioncountry

        .share{
          margin-left: auto;
          padding-bottom: 3px;
            img{
              width: 20px;
            }

        }

      } //.contenthead

      .contentinner {

        &.ads{
          justify-content: center;
        }

        margin: 8px 14px 0px 14px;
        padding-bottom: 8px;
        border-bottom: 1px solid #F7F6EB;

        .cnt-img{
          display: flex;
          align-items: center;
          justify-content: center;
          height:170px;
          min-width: 320px;
          background-color: #222222;
          overflow: hidden;

          img{
            width: 100%;
            height: auto;
          }
        }//.cnt-img

        .contenttitle{
          font-style: normal;
          font-weight: bold;
          font-size: 17px;
          line-height: 25px;
          color: #231F20;
          margin-top: 7px;

          word-wrap: break-word;
          display: -webkit-box;
          /* autoprefixer: off */
          -webkit-box-orient: vertical;
          /* autoprefixer: on */
          -webkit-line-clamp: 6;
          overflow: hidden;

        } //.contenttitle
      } //.contentinner
    } //.contentwrap

    .contentfooter {
      margin: 9px 10px 0px 17px;

      .footerupper{
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #979797;

        .topflag{
          width: 20px;
          height: 12px;
          margin: 0px 5px 0px 0px;

          img{
            width: auto;
            height: 12px;
            border: 0.5px solid #979797;
            box-sizing: border-box;
          }
        }

        .topfrom{
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 12px;
          text-align: center;
          color: #979797;

        }


      } //.footerupper

      .topcomment {
        margin-top: 9px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #231F20;

        word-wrap: break-word;
        display: -webkit-box;
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
        -webkit-line-clamp: 3;
        overflow: hidden;

      }


    } //.contentfooter

    &.dummy{
      height: 20px;
      opacity: 0;
    }

  } //.content

@media screen and (min-width: 768px){

  #adlinkArea{
    display: none;
  }
  .cntrankwrap{
    .topcnt{
      width: 100%;
    }
  }

    .content {

        margin: 0px 37px 0px 37px;
        padding-bottom: 13px;
        background: #FFFFFF;
        border-top: 1px solid #D8D8D8;

        &.ads {

          .contenthead {
            margin: 10px 0px 13px 1px;

          }

          .contentinner{
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: none;

            &.ads{
              justify-content: center;
            }

          }

        } //.&ads

      .contentwrap {
        // border-bottom: 1px solid #F7F6EB;
        // padding-bottom: 13px;
        display: flex;

          .cnt-img{
            flex: none;
            margin-top: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 320px;
            height:256px;
            background-color: #222222;
            overflow: hidden;
            img{
              width: 320px;
              height:256px;
              object-fit: cover;
            }

          }//.cnt-img

      .contentwidewrap{
        width:100%;

        .contenthead {

          display: flex;
          margin: 10px 16px 0px 12px;
          align-items: center;

          .reactioncountry{
            flex-wrap: nowrap;
            width: auto;
          }

        } //.contenthead

        .contentinner {
          border-bottom: none;
          display: flex;
          flex-direction: column;
          margin-right: 16px;
          margin-left: 12px;
          height: 100%;

          .contenttitle{
            font-style: normal;
            font-weight: bold;
            font-size: 19px;
            line-height: 36px;
            color: #231F20;
            margin-top: 7px;

            word-wrap: break-word;
            display: -webkit-box;
            /* autoprefixer: off */
            -webkit-box-orient: vertical;
            /* autoprefixer: on */
            -webkit-line-clamp: 6;
            overflow: hidden;

          } //.contenttitle

          &.ads{
            justify-content: center;
          }

          &.fw {
            height: 80%;
          }

          .cnt-img{
              display: none;

          }//.cnt-img
        } //.contentinner
      } //.contentwidewrap
      } //.contentwrap


      .widespace {
        margin-left: 10px;
        margin-right: 10px;
        border-bottom: 1px solid #F7F6EB;
        height: 13px;
        background-color: #FFFFFF;
        display: block;
      }

      .contentfooter {

          margin: 9px 16px 0px 16px;

          .topcomment {
            margin-top: 9px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #231F20;

            word-wrap: break-word;
            display: -webkit-box;
            /* autoprefixer: off */
            -webkit-box-orient: vertical;
            /* autoprefixer: on */
            -webkit-line-clamp: 3;
            overflow: hidden;

          }


      } //.contentfooter

      &.dummy{
        height: 20px;
        opacity: 0;
      }
    } //.content
} //768

@media screen and (min-width: 1280px){

  .cntrankwrap{
    .topcnt{
      width: 68%;
    }
  }

  #Sec02{
    width: auto;
    .inner{

      .cntlinkwrap{
        display: flex;

        #adlinkArea{

          margin-left: 23px;
          margin-right: 30px;
          display: block;
          width: 367px;

          .sidebar{
            position: sticky;
            top:0;

            .content {
              margin: 0px 0px 0px 0px;
              padding: 0px 37px 13px 37px;
            }

            .bannerBox{
              display: flex;
              flex-direction:column;
              align-items: center;
              margin-top: 47px;
              .link-ios{
                display: block;
                .banner-ios{
                  height: 40px;
                }
              }

              .dummy{
                height: 17px;
              }

              .link-android{
                display: block;
                .banner-android{
                height: 40px;
                }
              }
            }

            .app_text{
              margin-top: 28px;
              font-style: normal;
              font-weight: bold;
              font-size: 15px;
              line-height: 22px;
              text-align: center;
              color: #231F20;
            }

            .sidelink{
              display: flex;
              flex-direction: column;
              margin:40px 0px 0px 23px;

              a{
              font-style: normal;
              font-weight: normal;
              font-size: 15px;
              line-height: 30px;
              color: #FF233E;
              }
            }
          }

        }


      }//.cntlinkwrap

    }//.inner
  }//#Sec02
} //@1280
/*--------------------------------------------------------------------------
   ロードスピナー
---------------------------------------------------------------------------*/

.loader {
  color: #000000;
  font-size: 10px;
  margin: 200px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }

} //.loader

/*--------------------------------------------------------------------------
   Footer
---------------------------------------------------------------------------*/
#Footer{}

/* Main
-----------------------------------------------------------------*/
#Main{}



@media screen and (max-width: 768px) {
/* 768pxまでの幅の場合に適応される */

/*--------------------------------------------------------------------------
     Header
  ---------------------------------------------------------------------------*/
#Header{

  .global_navi{
    display: block;

    .global_navi_inner{
      width: 100%;
      margin: 0 auto;

    } //.global_navi_inner

  } //.global_navi

} //#Header

/*--------------------------------------------------------------------------
     Content
  ---------------------------------------------------------------------------*/

/* Main
-----------------------------------------------------------------*/
#Main{

  .inner{
  }

} //#Main

/* media-screen(768) */
}
