
.privacy{

  #Sec01{
    margin: 0 15px 10px 15px;

    .inner{
      position: relative;

      h1.page_title {
        padding-top: 30px;
        font-size: 1.8rem;
        font-weight: 600;
      }

      h2.heading {
        padding-top: 30px;
        font-size: 1.5rem;
        font-weight: 600;
      }

      .str {
        padding-top: 10px;
      }

      .caution {
        color: #FF233E;
        font-size: 0.7rem;
        margin-left: 2em;
        padding:0 0 0 2em;
        font-weight: 400;

        &:before {
          display: marker;
          content: "※";
          margin-left: -2em;
        }
      }

      ol.list_str{
        padding:0 0 0 2em;
        margin:0;
        margin-top: 10px;
      }

      ol.list_str li{
        list-style-type:none;
        list-style-position:inside;
        counter-increment: cnt;
        font-size: 1.2rem;
        padding-top: 5px;
      }
      ol.list_str li:before{
        display: marker;
        content: "(" counter(cnt) ") ";
        margin-left: -2em;
      }

      ol.list_str2{
        padding:0 0 0 2em;
        margin:0;
        margin-top: 10px;
        --sec-num: "1";
        &.sec3 {
          --sec-num: "3";
        }
        &.sec4 {
          --sec-num: "4";
        }
        &.sec7 {
          --sec-num: "7";
        }
      }
      ol.list_str2 li{
        list-style-type:none;
        list-style-position:inside;
        counter-increment: cnt;
        font-size: 1.2rem;
      }
      ol.list_str2 li:before{
        display: marker;
        content: var(--sec-num)"-"counter(cnt)" ";
        margin-left: -2em;
      }

      .scroll{
        max-width: 100%;
          overflow: auto;
      }
      .scroll::-webkit-scrollbar{
        height: 5px;
      }
      .scroll::-webkit-scrollbar-track{
        background: #F1F1F1;
      }
      .scroll::-webkit-scrollbar-thumb {
        background: #BCBCBC;
      }

      table.tbl {
        margin-top: 5px;
        width: 100%;

        tr {
          counter-increment: cnt_tbl;
        }

        td.left {
          min-width: 300px;
          border: 1px solid $gray;
          border-right: 0px solid $gray;
          padding: 5px 5px 5px 30px;

          &:before{
            display: marker;
            content: "(" counter(cnt_tbl) ") ";
            margin-left: -20px;
          }

        }
        td.right {
          min-width: 400px;
          border: 1px solid $gray;
          padding: 5px 10px;
        }

      }

    } //.inner

  } //#Sec01


} //.login
