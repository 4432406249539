
#modal-share {
  display: none;
}

.modal-share-custom {
 .modaal-container {
   //border-radius: 5%;
   max-width: 400px;
   width: 80%;

   .modaal-content-container {
    padding: 10px 5px !important;

      .modal_share_body {

        .btnBox {
          margin-top: 20px;
          margin-bottom: 20px;
          display: flex;
          justify-content: center;

          .btn {
            display: block;
            border-radius: 0;
            width: 90%;
            font-size: 1.6rem;

            &.btn-twitter {
              background: #48a6f7;
              border: 1px solid #48a6f7;
              color: #FFFFFF;
            }
            &.btn-fb {
              background: #4267b2;
              border: 1px solid #4267b2;
              color: #FFFFFF;
            }
            &.btn-line {
              background: #54b635;
              border: 1px solid #54b635;
              color: #FFFFFF;
            }
            &.btn-clipboard {
              background: $gray;
              border: 1px solid $gray;
              color: #000000;
            }
          }

        } //.btnBox

      } //.modal_share_body

    } //.modaal-content-container

  } //.modaal-container
} //.modal-share-custom
